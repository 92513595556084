import React, { useState, useEffect } from 'react';
import Modal from '../modal';
import styled from 'styled-components';
import Input from '../input';
import Select from '../select';
import { partyInviteOpts, bannerStatusOpts, bannerTypeOpts } from '../../utils/constant';
import { showErrorNotification, showSuccessNotification } from '../../lib/notificationManager';
import Button from '../button';
import { useQuery } from '@apollo/client';
import { GET_ALL_BANNERS } from '../../lib/graphQl/query';
import { createBannerImage } from '../../lib/restService';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormField = styled.div`
  margin-bottom: 1rem;
`;

const NoftTriggerForm = ({
  graphReq = async ({}) => {},
  selected = false,
  noftTitle = '',
  typeofNoft = '',
  inputName = '',
  inputLabel = '',
  inputPlaceholder = '',
  cancel = () => {},
}) => {
  const [inputValue, setInputValue] = useState('');
  const [desc, setDesc] = useState('');
  const [link, setLink] = useState('');
  const [file, setFile] = useState(null);
  const [selectValue, setSelectValue] = useState(partyInviteOpts[0].value);
  const [selectBannerValue, setSelectBannerValue] = useState('');
  const [bannerType, setBannerType] = useState();
  const [loading, setLoading] = useState(false);
  const [bannerId, setBannerId] = useState('');
  const [isProcessing, setProcessing] = useState(false);
  const { data, loading: bannersLoading } = useQuery(GET_ALL_BANNERS);
  const banners = data?.getAllBanners?.data || [];

  const updateBannerStatusOpts = [
    { label: 'Active', value: 'ACTIVE' },
    { label: 'Inactive', value: 'INACTIVE' },
  ];

  useEffect(() => {
    if (typeofNoft === 'updateBanner' && bannerId && banners.length > 0) {
      const selectedBanner = banners.find(b => b.id === bannerId);
      if (selectedBanner) {
        setSelectBannerValue(selectedBanner.status);
      }
    }
  }, [bannerId, banners, typeofNoft]);

  const onSelect = ({ value }) => {
    setSelectValue(value);
  };

  const onBannerSelect = ({ value }) => {
    setSelectBannerValue(value);
  };

  const onTitleSelect = ({ value }) => {
    setBannerId(value);

    const selectedBanner = banners.find(b => b.id === value);
    if (selectedBanner) {
      setSelectBannerValue(selectedBanner.status);
    }
  };

  const onChange = (value) => {
    setInputValue(value);
  };

  const descChangeHandler = (value) => {
    setDesc(value);
  };

  const linkChangeHandler = (value) => {
    setLink(value);
  };

  const handleBannerTypeChange = ({value}) => {
    setBannerType(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (!inputValue && typeofNoft !== 'updateBannerStatus') {
        throw new Error(`Enter a valid ${inputLabel} value`);
      }

      if (typeofNoft === 'partyInvitation' && !selectValue) {
        throw new Error('Select an invite type');
      }

      if (typeofNoft === 'sendGameTip' && !desc) {
        throw new Error("Enter the tip's description");
      }

      if (typeofNoft === 'createBanner' && !selectBannerValue) {
        throw new Error('Select a banner status');
      }

      if (typeofNoft === 'createBanner' && !bannerType) {
        throw new Error('Select a banner type');
      }

      const formData = {};
      const uploadData = new FormData();

      if (typeofNoft === 'partyInvitation') {
        formData['type'] = selectValue;
        formData['details'] = inputValue;
      } else if (typeofNoft === 'notifyOfNewFeature') {
        formData['feature'] = inputValue;
      } else if (typeofNoft === 'sendGameTip') {
        formData['title'] = inputValue;
        formData['body'] = desc;
      }

      if (typeofNoft === 'createBanner') {
        try {
          setProcessing(true);
          let response;

          if (bannerType === "MODAL" && file) {
            uploadData.append("intention", "banner");
            uploadData.append("file", file);

            response = await createBannerImage(uploadData);

            if (response?.data?.status === 'success') {
              formData['title'] = inputValue;
              formData['description'] = desc;
              formData['link'] = link;
              formData['status'] = selectBannerValue;
              formData['image'] = response.data?.link;
              formData['type'] = bannerType;
            } else {
              showErrorNotification("File upload failed.")
            }
          } else {
            formData['title'] = inputValue
            formData['link'] = link;
            formData['type'] = bannerType;
            formData['status'] = selectBannerValue;
          }
        } catch (e) {
          showErrorNotification(e.message);
          return;
        } finally {
          setProcessing(false);
        }
      }

      if (typeofNoft === 'updateBannerStatus') {
        if (!bannerId) {
          throw new Error('Please select a banner');
        }
        formData['id'] = bannerId;
        formData['status'] = selectBannerValue;
      }

      const { data } = await graphReq({
        variables: formData,
        refetchQueries: [{ query: GET_ALL_BANNERS }],
        awaitRefetchQueries: true,
      });

      if (data[typeofNoft].status === 'failed') {
        throw new Error(data[typeofNoft].message);
      }

      setInputValue('');
      setSelectValue('');
      setBannerType('');
      setSelectBannerValue('');
      setBannerId('');
      if (desc) setDesc('');
      if (link) setLink('');
      showSuccessNotification(data[typeofNoft]?.message);
      cancel();
    } catch (err) {
      showErrorNotification(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal isActive={selected} onCancel={cancel} title={noftTitle}>
        <Form onSubmit={onSubmit}>
          {typeofNoft !== 'updateBannerStatus' && (
            <FormField>
              <Input
                onChange={onChange}
                name={inputName}
                label={inputLabel}
                value={inputValue}
                placeholder={inputPlaceholder}
              />
            </FormField>
          )}
          {typeofNoft === 'sendGameTip' && (
            <FormField>
              <Input
                onChange={descChangeHandler}
                name="tip_desc"
                label="Game tip description"
                value={desc}
                placeholder="Enter the game's tip description"
              />
            </FormField>
          )}

          {typeofNoft === 'partyInvitation' && (
            <FormField>
              <Select
                options={partyInviteOpts}
                value={selectValue}
                name="party_invite"
                label="Party Invitation"
                fullWidth={false}
                onSelect={onSelect}
              />
            </FormField>
          )}

          {typeofNoft === 'createBanner' && (
            <FormField>
              <Select
                options={bannerTypeOpts}
                value={bannerType}
                name="banner_type"
                label="Banner Type"
                onSelect={handleBannerTypeChange}
              />

              {bannerType === 'MODAL' && (
                <>
                  <Input
                    onChange={descChangeHandler}
                    name="banner_desc"
                    label="Banner description"
                    value={desc}
                    placeholder="Enter the banner's description"
                  />

                  <label
                    htmlFor="file"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '8px',
                      marginTop: '8px',
                      fontSize: '14px',
                      color: 'black',
                    }}
                  >
                    Image
                  </label>
                  <input
                    style={{ marginBottom: '8px' }}
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </>
              )}

              <Input
                onChange={linkChangeHandler}
                name="banner_link"
                label="Banner link"
                value={link}
                placeholder="Enter the banner's link"
              />

              <Select
                options={bannerStatusOpts}
                value={selectBannerValue}
                name="banner_status"
                label="Banner status"
                fullWidth={false}
                onSelect={onBannerSelect}
              />
            </FormField>
          )}

          {typeofNoft === 'updateBannerStatus' && (
            <FormField>
              {bannersLoading ? (
                <p>Loading banners...</p>
              ) : (
                <>
                  <Select
                    options={banners.map(b => ({
                      label: b.title,
                      value: b.id,
                    }))}
                    value={bannerId}
                    name="banner_title"
                    label="Banner title"
                    fullWidth={false}
                    onSelect={onTitleSelect}
                  />

                  <Select
                    options={updateBannerStatusOpts}
                    value={selectBannerValue}
                    name="banner_status"
                    label="Banner status"
                    fullWidth={false}
                    onSelect={onBannerSelect}
                  />
                </>
              )}
            </FormField>
          )}

          <Button type="submit" fullWidth loading={loading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default NoftTriggerForm;
