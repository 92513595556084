import React, { useEffect } from 'react';
import Table from '../../components/table';
import TextContent from '../../components/textContent';
import Colour from '../../lib/colour';
import Tag from '../../components/tag';
import PageTitle from '../../components/pageTitle';
import TableTools from '../../components/tableTools';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_BUNDLES, GET_USERS } from '../../lib/graphQl/query';
import downloadAsCSV from '../../lib/exportCSV';
import { dateComparator } from '../../lib/sort';
import { DateTime } from 'luxon';
import { showSuccessNotification } from '../../lib/notificationManager';

const pageLimit = 20;
export default function Users() {
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState(null);
  const [allBundleData, setAllBundleData] = React.useState({});
  const [downloadFileStatus, setDownloadFileStatus] = React.useState('');
  const [sortValue, setSortValue] = React.useState({
    label: 'Date-Descending',
    value: 'date_descending',
    sort: 'DESCENDING',
    type: 'DATE',
  });

  const { data, loading } = useQuery(GET_USERS, {
    variables: {
      limit: pageLimit,
      offset: offset,
      nameLike: searchQuery,
      sortType: sortValue.type,
      sortOrder: sortValue.sort,
    },
  });

  console.log(data);

  const { loading: bundleLoading } = useQuery(GET_BUNDLES, {
    variables: {
      limit: 10,
      offset: 0,
    },
    onCompleted: (data) => {
      setAllBundleData({ ...data });
    },
  });
  console.log(allBundleData);

  const [
    getDownloadableUserData,
    { loading: downloadableDataLoading, error: downloadableDataError, data: downloadableData },
  ] = useLazyQuery(GET_USERS);

  useEffect(() => {
    if (!downloadableDataLoading && downloadableData && downloadFileStatus == 'awaiting') {
      downloadAsCSV(
        downloadableData.adminListUsers.data.map((user) => ({
          name: user.fullname,
          email: user.email,
          type: user.userExperience.join(', '),
          totalGamesPlayed: user.totalGamesPlayed,
          registered: user.createdAt,
          lastPlayed: user.lastPlayedGameTime,
          location: user.location,
        })),
        'Users'
      );
      showSuccessNotification('File downloaded successfully');
      setDownloadFileStatus((prevState) => '');
    }
  }, [downloadableData]);

  const columns = [
    {
      title: 'USERS',
      dataIndex: 'fullname',
      fixed: 'left',
      width: 180,
      render: (text) => (
        <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
          {text}
        </TextContent>
      ),
    },
    { title: 'EMAIL ADDRESSES', dataIndex: 'email', width: 180 },
    {
      title: 'TYPE',
      dataIndex: 'userExperience',
      width: 180,
      sorter: (a, b) => a.userExperience.length - b.userExperience.length,
      align: 'center',
      render: (text) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {text.join(', ')}
        </TextContent>
      ),
    },
    {
      title: 'ACTIVE PLAN',
      width: 180,
      dataIndex: 'activePlan',
      sorter: (a, b) => a.activePlan.length - b.activePlan.length,
      align: 'center',
      render: (isPlanActive) => (
        <Tag tagLabel={isPlanActive ? 'Active' : 'Inactive'} variant={isPlanActive ? 'success' : 'error'} />
      ),
    },
    {
      title: 'PLAN TYPE',
      width: 180,
      dataIndex: 'userBundleType',
      sorter: (a, b) => a.userBundleType - b.userBundleType,
      align: 'center',
      render: (text) =>
        allBundleData?.adminListBundle?.data.map((bundleInfo) => {
          if (bundleInfo.id === text) {
            return (
              <TextContent key={bundleInfo.id} fontSize={14} colour={Colour.BlackText}>
                {bundleInfo.title}
              </TextContent>
            );
          } else {
            return (
              <TextContent key={bundleInfo.id} fontSize={14} colour={Colour.BlackText}>
                {''}
              </TextContent>
            );
          }
        }),
    },
    {
      title: 'GAMES HOSTED',
      dataIndex: 'totalGamesHosted',
      sorter: (a, b) => a.totalGamesHosted - b.totalGamesHosted,
      width: 180,
      render: (text) => (
        <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
          {text}
        </TextContent>
      ),
    },
    {
      title: 'LAST HOSTED',
      dataIndex: 'lastHostedGameTime',
      sorter: (a, b) =>
        (DateTime.fromISO(a.lastHostedGameTime).toMillis() || 0) -
        (DateTime.fromISO(b.lastHostedGameTime).toMillis() || 0),
      // fixed: 'right',
      width: 170,
      render: (time) =>
        time ? (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {DateTime.fromISO(time).toFormat('dd-MM-y')}{' '}
            <TextContent fontSize={10} colour={Colour.Grey}>
              {DateTime.fromISO(time).toFormat('HH:mm')}
            </TextContent>
          </TextContent>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'GAMES PLAYED',
      dataIndex: 'totalGamesPlayed',
      width: 180,
      align: 'center',
      sorter: (a, b) => a.totalGamesPlayed - b.totalGamesPlayed,
    },
    {
      title: 'GAMES WON',
      dataIndex: 'totalGamesWon',
      align: 'center',
      width: 180,
      sorter: (a, b) => a.totalGamesWon - b.totalGamesWon,
    },
    {
      title: 'LAST WON',
      dataIndex: 'lastWonGameTime',
      sorter: (a, b) =>
        (DateTime.fromISO(a.lastWonGameTime).toMillis() || 0) - (DateTime.fromISO(b.lastWonGameTime).toMillis() || 0),
      // fixed: 'right',
      width: 170,
      render: (time) =>
        time ? (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {DateTime.fromISO(time).toFormat('dd-MM-y')}{' '}
            <TextContent fontSize={10} colour={Colour.Grey}>
              {DateTime.fromISO(time).toFormat('HH:mm')}
            </TextContent>
          </TextContent>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'WINNING STREAK',
      dataIndex: 'winningStreak',
      width: 180,
      sorter: (a, b) => {
        const countA =
          a.winningStreak && a.winningStreak.length > 0 ? a.winningStreak[a.winningStreak.length - 1].count : 0;
        const countB =
          b.winningStreak && b.winningStreak.length > 0 ? b.winningStreak[b.winningStreak.length - 1].count : 0;
        return countA - countB;
      },
      render: (winningStreak) => {
        const latestStreak =
          winningStreak && winningStreak.length > 0 ? winningStreak[winningStreak.length - 1].count : 0;

        return (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {latestStreak}
          </TextContent>
        );
      },
    },
    {
      title: 'STREAK',
      dataIndex: 'dailyGameStreak',
      width: 180,
      sorter: (a, b) => {
        const countA =
          a.dailyGameStreak && a.dailyGameStreak.length > 0 ? a.dailyGameStreak[a.dailyGameStreak.length - 1].count : 0;
        const countB =
          b.dailyGameStreak && b.dailyGameStreak.length > 0 ? b.dailyGameStreak[b.dailyGameStreak.length - 1].count : 0;
        return countA - countB;
      },
      render: (dailyGameStreak) => {
        const streak =
          dailyGameStreak && dailyGameStreak.length > 0 ? dailyGameStreak[dailyGameStreak.length - 1].count : 0;

        return (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {streak}
          </TextContent>
        );
      },
    },
    {
      title: 'STATUS',
      width: 180,
      dataIndex: 'accountActive',
      sorter: (a, b) => a.accountActive && b.accountActive,
      align: 'center',
      render: (isActive) => (
        <Tag tagLabel={isActive ? 'Active' : 'Inactive'} variant={isActive ? 'success' : 'error'} />
      ),
    },
    {
      title: 'CREATED',
      dataIndex: 'dateRegistered',
      sorter: (a, b) => DateTime.fromISO(a.dateRegistered).toMillis() - DateTime.fromISO(b.dateRegistered).toMillis(),
      // fixed: 'right',
      width: 170,
      render: (time) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {DateTime.fromISO(time).toFormat('dd-MM-y')}{' '}
          <TextContent fontSize={10} colour={Colour.Grey}>
            {DateTime.fromISO(time).toFormat('HH:mm')}
          </TextContent>
        </TextContent>
      ),
    },
    {
      title: 'LAST PLAYED',
      dataIndex: 'lastPlayedGameTime',
      sorter: (a, b) =>
        (DateTime.fromISO(a.lastPlayedGameTime).toMillis() || 0) -
        (DateTime.fromISO(b.lastPlayedGameTime).toMillis() || 0),
      // fixed: 'right',
      width: 170,
      render: (time) =>
        time ? (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {DateTime.fromISO(time).toFormat('dd-MM-y')}{' '}
            <TextContent fontSize={10} colour={Colour.Grey}>
              {DateTime.fromISO(time).toFormat('HH:mm')}
            </TextContent>
          </TextContent>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'LOCATION',
      dataIndex: 'location',
      align: 'center',
      width: 180,
      render: (text) =>
        text ? (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {text}
          </TextContent>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'HOST STREAK',
      dataIndex: 'gameHostedStreak',
      sorter: (a, b) => {
        const countA =
          a.gameHostedStreak && a.gameHostedStreak.length > 0
            ? a.gameHostedStreak[a.gameHostedStreak.length - 1].count
            : 0;
        const countB =
          b.gameHostedStreak && b.gameHostedStreak.length > 0
            ? b.gameHostedStreak[b.gameHostedStreak.length - 1].count
            : 0;
        return countA - countB;
      },
      width: 120,
      // fixed: 'right',
      render: (text) => {
        const streak = text && text.length > 0 ? text[text.length - 1].count : 0;

        return (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {streak}
          </TextContent>
        );
      },
    },
  ];

  const onDownload = async () => {
    if (data?.adminListUsers?.data) {
      getDownloadableUserData({
        variables: {
          limit: 0,
          offset: 0,
        },
        fetchPolicy: 'network-only',
      });
      setDownloadFileStatus('awaiting');
      showSuccessNotification('Please wait while your file is fetched');
    }
  };

  function onPrevPage() {
    setOffset((page - 2) * pageLimit);
    setPage((prevPage) => prevPage - 1);
  }

  function onNextPage() {
    setOffset(page * pageLimit);
    setPage((prevPage) => prevPage + 1);
  }

  function onSortChange(newSortValue) {
    setSortValue({ ...newSortValue });
  }

  return (
    <div>
      <PageTitle title="Users" />
      <TableTools
        onSearch={setSearchQuery}
        onDownload={onDownload}
        searchPlaceholder="Search by email"
        searchValue={searchQuery}
        sortValue={sortValue}
        onSortSelect={onSortChange}
        canSearch
        canDownload
        canSort
      />
      <div className='tWidthUser'>
      <Table
        columns={columns}
        data={data?.adminListUsers?.data || []}
        loading={loading}
        page={page}
        limit={pageLimit}
        total={data?.adminListUsers?.totalCount || 1}
        showPagination
        scroll={{
          x : 2600,
          y : 450,
          }}
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
      />
      </div>
    </div>
  );
}
