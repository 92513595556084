import React from "react";
import PageTitle from "../../components/pageTitle";
import TextContent from "../../components/textContent";
import Colour from "../../lib/colour";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { GET_SINGLE_GAME_WITH_USER } from "../../lib/graphQl/query";
import PlaylistImage from "../../components/playlistImage";
import Loader from "../../components/loader";
import { DateTime } from 'luxon';
import { useHistory } from "react-router-dom";

const TitleWrapper = styled.div`
    display: flex;
    margin-top: 16px;
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    margin-left: 16px;
    padding-bottom: 8px;
    
    > span:last-child {
        margin-top: auto;
    }
`;

const DetailList = styled.div`
    margin-bottom: 4%;
    gap: 30px;
    border: 1px solid blue;
    border-radius: 10px 30px;
    box-shadow: 10px 10px 5px lightblue;
    padding-left: 14px;
    padding-bottom: 10px;
`;

const Detail = styled.div`
   display: flex;
   gap: 17px;
   margin-bottom: 4%;
`

const PlayerItem = styled.span`
    display: block;
    font-size: 14px;
    margin-left: 10px;
    color: ${Colour.BlackText};
`;

const Item = styled.span`
     font-size: 16px;
    margin-left: 10px;
    color: ${Colour.BlackText};
`

const Players = styled.div`
    display: flex;
    gap: 15px;
`


const pageLimit = 10
export default function ViewGame(props) {
    const [isLoading, setLoading] = React.useState(true);
    const [gameData, setGameData] = React.useState(null);
    const [offset, setOffset] = React.useState(0);
    const history = useHistory();

  React.useEffect(() => {
      if (!props.match?.params?.gameId) {
          history.goBack();
      }

  }, [props.match?.params?.gameId, history])

    const { loading } = useQuery(GET_SINGLE_GAME_WITH_USER, {
        variables: {
            gameId: props.match?.params?.gameId,
            limit: pageLimit,
            offset,
        },
        onCompleted: (data) => {
            setGameData({ ...data });
            setLoading(false);
            console.log(data)
        },
    })
    

    if (isLoading) {
        return <Loader />;
    }

    if (!gameData?.adminSearchUserByGame?.game) {
        history.goBack();
    }

    const timeStart = gameData?.adminSearchUserByGame?.game?.gameStartedDateTime
    const timeEnd = gameData?.adminSearchUserByGame?.game?.gameCompletedDateTime

    const players = gameData?.adminSearchUserByGame?.game?.players;

    return (
        <div>
            <PageTitle
              replaceComponent={(
                <TitleWrapper>
                   <PlaylistImage 
                     image={gameData?.adminSearchUserByGame?.playlist.imageUrl}
                   />
                   <Details>

                    <TextContent fontSize={16} fontWeight="500">
                        TITLE:
                        <Item>
                            {gameData?.adminSearchUserByGame?.game.gameTitle || "N/A"}
                        </Item>
                    </TextContent>
                    <TextContent fontSize={16} fontWeight="500" gap={10}>
                        HOST:
                        <Item>
                            {gameData?.adminSearchUserByGame?.host.fullname}
                        </Item>
                    </TextContent>
                    <TextContent fontSize={16} fontWeight="500">
                        PLAYLIST:
                        <Item>
                            {gameData?.adminSearchUserByGame?.playlist.title}
                        </Item>
                    </TextContent>
                    <TextContent fontSize={16} fontWeight="500">
                        PLAYERS:
                        <Item>
                            {players.length}
                        </Item>
                    </TextContent>
                    <TextContent fontSize={16} fontWeight="500">
                       PRIZE:
                        <Item fontSize={14} colour={Colour.BlackText}>
                            {gameData?.adminSearchUserByGame?.prize?.gamePrize || "N/A"}
                        </Item>
                    </TextContent>
                    <TextContent fontSize={16} fontWeight="500">
                        WINNER:
                        <Item fontSize={14} colour={Colour.BlackText} >
                            {gameData?.adminSearchUserByGame?.game?.winnerFullName || 'N/A'}
                        </Item>
                    </TextContent>
                   </Details>
                </TitleWrapper>
              )}
              goBackText="Back to All Games"
              canGoBack
            />
            <Detail>
                 <div>
                 <TextContent fontSize={16} fontWeight="500" display="block">
                        GAME MODE
                   </TextContent>
                      <div style={{ textAlign: "center"}}>
                        <TextContent fontSize={16} >
                                {gameData?.adminSearchUserByGame?.game?.gameMode || 'N/A'}
                        </TextContent>
                    </div>  
                </div>
                 <div>
                 <TextContent fontSize={16} fontWeight="500" display="block">
                        GAME TYPE
                   </TextContent>
                      <div style={{ textAlign: "center"}}>
                        <TextContent fontSize={16} >
                                {gameData?.adminSearchUserByGame?.game?.gameType || 'N/A'}
                        </TextContent>
                    </div>  
                </div>
                 <div>
                 <TextContent fontSize={16} fontWeight="500" display="block">
                        STREAM MODE
                   </TextContent>
                      <div style={{ textAlign: "center"}}>
                        <TextContent fontSize={16} >
                                {gameData?.adminSearchUserByGame?.game?.streamMode || 'N/A'}
                        </TextContent>
                    </div>  
                </div>
               <div>
                 <TextContent fontSize={16} fontWeight="500" display="block">
                        GAME CODE
                   </TextContent>
                      <div style={{ textAlign: "center"}}>
                        <TextContent fontSize={16} >
                                {gameData?.adminSearchUserByGame?.game?.gameCode || 'N/A'}
                        </TextContent>
                    </div>  
                </div>
               <div>
                 <TextContent fontSize={16} fontWeight="500" display="block">
                        GAME START DATE
                   </TextContent>
                      <div style={{ textAlign: "center"}}>
                        <TextContent fontSize={14} >
                        {timeStart
                            ? `${DateTime.fromISO(timeStart).toFormat('dd-MM-y')} ${DateTime.fromISO(timeStart).toFormat('HH:mm')}`
                            : 'N/A'}
                        </TextContent>
                    </div>  
                </div>
               <div>
                 <TextContent fontSize={16} fontWeight="500" display="block">
                        GANE STARTED
                   </TextContent>
                      <div style={{ textAlign: "center"}}>
                        <TextContent fontSize={16} >
                                {gameData?.adminSearchUserByGame?.game?.gameStarted ? "True"  : "False" }
                        </TextContent>
                    </div>  
                </div>
                <div>
                 <TextContent fontSize={16} fontWeight="500" display="block">
                        GAME END DATE
                   </TextContent>
                      <div style={{ textAlign: "center"}}>
                        <TextContent fontSize={14} >
                        {timeEnd
                            ? `${DateTime.fromISO(timeEnd).toFormat('dd-MM-y')} ${DateTime.fromISO(timeEnd).toFormat('HH:mm')}`
                            : 'N/A'}
                        </TextContent>
                    </div>  
                </div>
               <div>
                 <TextContent fontSize={16} fontWeight="500" display="block">
                        GAME COMPLETED
                   </TextContent>
                      <div style={{ textAlign: "center"}}>
                        <TextContent fontSize={16} >
                                {gameData?.adminSearchUserByGame?.game?.gameCompleted ? "True"  : "False" }
                        </TextContent>
                    </div>  
                </div>
            </Detail>
            <DetailList >
                <div style={{ marginBottom: "10px"}}>
                 <TextContent fontSize={18} fontWeight="500">PLAYERS</TextContent>
                </div>
            <Players style={{ display: 'flex'}}>
                <div>
                 <TextContent fontSize={16} fontWeight="500" display="block">
                    EMAIL
                  </TextContent>
                    {players.map((player) => (
                      <PlayerItem key={player.id} fontSize={16} display="block" textAlign="center">
                        {player.email}
                      </PlayerItem>
                    ))}
               </div>   
                <div>
                 <TextContent fontSize={16} fontWeight="500" display="block">
                    USERNAME
                  </TextContent>
                    {players.map((player) => (
                        <PlayerItem key={player.id} fontSize={16} textAlign="center">
                            {player.username}
                        </PlayerItem>
                    ))}
               </div>       
                <div>
                 <TextContent fontSize={16} fontWeight="500" display="block">
                    NAMES
                  </TextContent>
                    {players.map((player) => (
                        <PlayerItem key={player.id} fontSize={16} display="block" textAlign="center">
                            {player.fullname}
                        </PlayerItem>
                    ))}
               </div>       
                <div>
                 <TextContent fontSize={16} fontWeight="500" display="block">
                    ACTIVE
                  </TextContent>
                    {players.map((player) => (
                        <PlayerItem key={player.id} fontSize={16} display="block" textAlign="center">
                            {player.activePlan ? "True" : "False"}
                        </PlayerItem>
                    ))}
               </div>       
                <div>
                 <TextContent fontSize={16} fontWeight="500" display="block">
                    GAMES
                  </TextContent>
                    {players.map((player) => (
                        <PlayerItem key={player.id} fontSize={16} display="block" textAlign="center">
                            {player.totalGamesPlayed}
                        </PlayerItem>
                    ))}
               </div>       
                <div>
                 <TextContent fontSize={16} fontWeight="500" display="block">
                    LOCATION
                  </TextContent>
                    {players.map((player) => (
                      <PlayerItem key={player.id} fontSize={16}display="block" textAlign="center">
                        {player.location}
                      </PlayerItem>
                    ))}
               </div>  
               </Players>
               </DetailList>
               <DetailList>    
                <TextContent fontSize={18} fontWeight="500">HOST</TextContent>
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    EMAIL:
                  </TextContent>
                    <Item >
                       {gameData?.adminSearchUserByGame?.host?.email}
                     </Item>
               </div>   
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    NAME:
                  </TextContent>
                    <Item >
                       {gameData?.adminSearchUserByGame?.host?.fullname}
                    </Item>
               </div>       
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    USERNAME:
                  </TextContent>
                    <Item >
                       {gameData?.adminSearchUserByGame?.host?.username}
                    </Item>
               </div>       
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    ACTIVE PLAN:
                  </TextContent>
                    <Item >
                       {gameData?.adminSearchUserByGame?.host?.activePlan ? "True" : "false"}
                    </Item>
               </div>       
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    LOCATION:
                  </TextContent>
                     <Item >
                        {gameData?.adminSearchUserByGame?.host?.location}
                     </Item>
               </div>       
               </DetailList> 
               <DetailList>    
                <TextContent fontSize={18} fontWeight="500">WINNER</TextContent>
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    EMAIL:
                  </TextContent>
                    <Item >
                       {gameData?.adminSearchUserByGame?.winner?.email || "N/A"}
                     </Item>
               </div>   
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    NAME:
                  </TextContent>
                    <Item >
                       {gameData?.adminSearchUserByGame?.winner?.fullname || "N/A"}
                    </Item>
               </div>       
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    USERNAME:
                  </TextContent>
                    <Item >
                       {gameData?.adminSearchUserByGame?.winner?.username || "N/A"}
                    </Item>
               </div>       
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    ACTIVE PLAN:
                  </TextContent>
                    <Item >
                       {gameData?.adminSearchUserByGame?.winner?.activePlan ? "True" : "false"}
                    </Item>
               </div>       
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    LOCATION:
                  </TextContent>
                     <Item >
                        {gameData?.adminSearchUserByGame?.winner?.location || "N/A"}
                     </Item>
               </div>       
               </DetailList> 
               <DetailList>    
                <TextContent fontSize={18} fontWeight="500">PLAYLIST</TextContent>
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    ID:
                  </TextContent>
                    <Item >
                       {gameData?.adminSearchUserByGame?.playlist.id}
                     </Item>
               </div>   
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    TITLE:
                  </TextContent>
                    <Item >
                       {gameData?.adminSearchUserByGame?.playlist?.title}
                    </Item>
               </div>       
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    SEOTITLE:
                  </TextContent>
                    <Item >
                       {gameData?.adminSearchUserByGame?.playlist?.seoTitle || "N/A"}
                    </Item>
               </div>       
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    TOTALPLAYS:
                  </TextContent>
                     <Item >
                        {gameData?.adminSearchUserByGame?.playlist?.totalPlays}
                     </Item>
               </div>       
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    TOTALTRACKS:
                  </TextContent>
                     <Item >
                        {gameData?.adminSearchUserByGame?.playlist?.totalTracks}
                     </Item>
               </div>       
                <div>
                 <TextContent fontSize={16} fontWeight="500">
                    CLICKCOUNT:
                  </TextContent>
                     <Item >
                        {gameData?.adminSearchUserByGame?.clickCount?.totalTracks}
                     </Item>
               </div>       
               </DetailList> 
        </div>
    )
}