import React, { useEffect } from 'react';
import TextContent from '../../components/textContent';
import Tab from '../../components/tab';
import styled from 'styled-components';
import Colour from '../../lib/colour';
import Button from '../../components/button';
import { Link } from 'react-router-dom';
import { routes } from '../../components/navigation/routes';
import Table from '../../components/table';
import PageTitle from '../../components/pageTitle';
import { useQuery, useMutation } from '@apollo/client';
import { GET_PLATFORM_ANALYTICS, GET_TOP_USERS, GET_TRENDING_PLAYLIST, GET_CORE_METRICS } from '../../lib/graphQl/query';
import { authContext } from '../../components/authWrapper';
import Tag from '../../components/tag';
import { RADIO_REBOOT } from '../../lib/graphQl/mutation';
import { showErrorNotification, showSuccessNotification } from '../../lib/notificationManager';
import { DateTime } from 'luxon';
import { numberWithCommas } from '../../functools';

const TabItems = [
  { name: 'Today', key: 'TODAY' },
  { name: 'This Week', key: 'THIS_WEEK' },
  { name: 'This Month', key: 'THIS_MONTH' },
  { name: 'All Time', key: 'ALL_TIME' },
];

export const AnalyticsCard = styled.div`
  border-radius: 5px;
  padding: ${(props) => props.padding}px;
  background-color: ${(props) => props.colour};
  flex: 1;
`;

export const AnalyticsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;

  > div {
    margin-right: 16px;

    :last-child {
      margin-right: none;
    }
  }
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 32px 0px;
`;

const PlaylistImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > img {
    margin-right: 0px;

    :last-child {
      margin-right: none;
    }
  }
`;

const PlaylistImage = styled.img`
  border-radius: 10px;
  height: 140px;
  width: 180px;
`;
const InputWrapper = styled.div`
  margin-bottom: 2em;
`;
const InputButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${Colour.Primary};
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
`;
const InputLabel = styled.label`
  padding-right: 1em;
  color: ${Colour.BlackText};
  input {
    margin-left: 0.5em;
    border-radius: 5px;
  }
`;

export default function Dashboard() {
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [pageLimit, setPageLimit] = React.useState(10);
  const authContextProps = React.useContext(authContext);
  const [activeTab, setActiveTab] = React.useState('THIS_MONTH');
  const [userFilter, setUserFilter] = React.useState({
    allUsers: false,
    registeredUsers: true,
  });
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [sDate, setSDate] = React.useState('');
  const [eDate, setEDate] = React.useState('');
  const [manualDateChange, setManualDateChange] = React.useState(false);

  function onPrevPage() {
    setOffset((page - 2) * pageLimit);
    setPage((prevPage) => prevPage - 1);
  }

  function onNextPage() {
    setOffset(page * pageLimit);
    setPage((prevPage) => prevPage + 1);
  }

  function updatePageLimit() {
    if (pageLimit === 10) {
      setPageLimit(20);
    } else if (pageLimit === 20) {
      setPageLimit(50);
    } else if (pageLimit === 50) {
      setPageLimit(100);
    } else {
      setPageLimit(10);
    }
  }

  function returnPaginationToDefault() {
    setOffset(0);
    setPage(1);
  }

  useEffect(() => {
    returnPaginationToDefault();
  }, [activeTab]);

  const columns = [
    {
      title: 'USERS',
      dataIndex: 'fullname',
      width: 150,
      fixed: 'left',
      render: (text) => (
        <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
          {text}
        </TextContent>
      ),
    },
    { title: 'EMAIL ADDRESSES', dataIndex: 'email', width: 150,},
    {
      title: 'TYPE',
      dataIndex: 'userExperience',
      width: 150,
      sorter: (a, b) => a.userExperience.length - b.userExperience.length,
      align: 'center',
      render: (text) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {text.join(', ')}
        </TextContent>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'accountActive',
      sorter: (a, b) => a.accountActive && b.accountActive,
      align: 'center',
      width: 150,
      render: (isActive) => (
        <Tag tagLabel={isActive ? 'Active' : 'Inactive'} variant={isActive ? 'success' : 'error'} />
      ),
    },
    { title: 'GAMES PLAYED', dataIndex: 'totalGamesPlayed', align: 'center', width: 150 },
    {
      title: 'LAST PLAYED',
      dataIndex: 'lastPlayedGameTime',
      sorter: (a, b) =>
        DateTime.fromISO(a.lastPlayedGameTime).toMillis() - DateTime.fromISO(b.lastPlayedGameTime).toMillis(),
      width: 150,
      render: (time) =>
        time ? (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {DateTime.fromISO(time).toFormat('dd-MM-y')}{' '}
            <TextContent fontSize={10} colour={Colour.Grey}>
              {DateTime.fromISO(time).toFormat('HH:mm')}
            </TextContent>
          </TextContent>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'LAST WON',
      dataIndex: 'lastWonGameTime',
      sorter: (a, b) => DateTime.fromISO(a.lastWonGameTime).toMillis() - DateTime.fromISO(b.lastWonGameTime).toMillis(),
      width: 150,
      render: (time) =>
        time ? (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {DateTime.fromISO(time).toFormat('dd-MM-y')}{' '}
            <TextContent fontSize={10} colour={Colour.Grey}>
              {DateTime.fromISO(time).toFormat('HH:mm')}
            </TextContent>
          </TextContent>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'GAMES WON',
      width: 150,
      dataIndex: 'totalGamesWon',
      sorter: (a, b) => a.totalGamesWon && b.totalGamesWon,
      render: (text) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {text}
        </TextContent>
      ),
    },
    {
      title: 'WINNING STREAK',
      dataIndex: 'winningStreak',
      width: 150,
      sorter: (a, b) => {
        const countA =
          a.winningStreak && a.winningStreak.length > 0 ? a.winningStreak[a.winningStreak.length - 1].count : 0;
        const countB =
          b.winningStreak && b.winningStreak.length > 0 ? b.winningStreak[b.winningStreak.length - 1].count : 0;
        return countA - countB;
      },
      render: (winningStreak) => {
        const latestStreak =
          winningStreak && winningStreak.length > 0 ? winningStreak[winningStreak.length - 1].count : 0;

        return (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {latestStreak}
          </TextContent>
        );
      },
    },
    {
      title: 'STREAK',
      dataIndex: 'dailyGameStreak',
      width: 150,
      sorter: (a, b) => {
        const countA =
          a.dailyGameStreak && a.dailyGameStreak.length > 0 ? a.dailyGameStreak[a.dailyGameStreak.length - 1].count : 0;
        const countB =
          b.dailyGameStreak && b.dailyGameStreak.length > 0 ? b.dailyGameStreak[b.dailyGameStreak.length - 1].count : 0;
        return countA - countB;
      },
      render: (dailyGameStreak) => {
        const streak =
          dailyGameStreak && dailyGameStreak.length > 0 ? dailyGameStreak[dailyGameStreak.length - 1].count : 0;

        return (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {streak}
          </TextContent>
        );
      },
    },
    {
      title: 'LOCATION',
      dataIndex: 'location',
      width: 150,
      sorter: (a, b) => {
        const locationA = a.location || '';
        const locationB = b.location || '';
        return locationA.localeCompare(locationB);
      },
      align: 'center',
      render: (text) =>
        text ? (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {text}
          </TextContent>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'PLAYERS COUNT',
      width: 150,
      dataIndex: 'totalUniquePlayers',
      sorter: (a, b) => a.totalUniquePlayers - b.totalUniquePlayers,
      render: (text) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {text}
        </TextContent>
      ),
    },
    {
      title: 'TOTAL HOSTED',
      width: 150,
      dataIndex: 'totalGamesHosted',
      sorter: (a, b) => a.totalGamesHosted - b.totalGamesHosted,
      render: (text) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {text}
        </TextContent>
      ),
    },
    {
      title: 'LAST HOSTED',
      dataIndex: 'lastPlayedGameTime',
      sorter: (a, b) =>
        DateTime.fromISO(a.lastHostedGameTime).toMillis() - DateTime.fromISO(b.lastHostedGameTime).toMillis(),
      width: 150,
      render: (time) =>
        time ? (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {DateTime.fromISO(time).toFormat('dd-MM-y')}{' '}
            <TextContent fontSize={10} colour={Colour.Grey}>
              {DateTime.fromISO(time).toFormat('HH:mm')}
            </TextContent>
          </TextContent>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'HOST STREAK',
      dataIndex: 'gameHostedStreak',
      width: 150,
      sorter: (a, b) => {
        const countA =
          a.gameHostedStreak && a.gameHostedStreak.length > 0
            ? a.gameHostedStreak[a.gameHostedStreak.length - 1].count
            : 0;
        const countB =
          b.gameHostedStreak && b.gameHostedStreak.length > 0
            ? b.gameHostedStreak[b.gameHostedStreak.length - 1].count
            : 0;
        return countA - countB;
      },
      // fixed: 'right',
      render: (gameHostedStreak) => {
        const latestStreak =
          gameHostedStreak && gameHostedStreak.length > 0 ? gameHostedStreak[gameHostedStreak.length - 1].count : 0;

        return (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {latestStreak}
          </TextContent>
        );
      },
    },
  ];

  const getGrowthTrend = (present, past) => {
    if (present > past) return 'up';
    if (present < past) return 'down';
    return 'flat';
  };

  const GrowthArrow = ({ trend }) => {
    switch (trend) {
      case 'up':
        return <span style={{ color: 'green' }}>↑</span>;
      case 'down':
        return <span style={{ color: 'red' }}>↓</span>;
      case 'flat':
        return <span style={{ color: 'grey' }}>→</span>;
      default:
        return null;
    }
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const calculateDateRange = (period, startDate, endDate) => {
    const today = new Date();
    const startOfDay = new Date(today.setHours(0, 0, 0, 0));

    let currentStartDate, currentEndDate, prevStartDate, prevEndDate;

    const filter = {
      TODAY: startOfDay,
      THIS_WEEK: new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000),
      THIS_MONTH: new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000),
      ALL_TIME: new Date(2020, 0, 1),
    };

    if (startDate && endDate) {
      currentStartDate = new Date(startDate);
      currentEndDate = new Date(endDate);
      const diff = currentEndDate - currentStartDate;

      prevStartDate = new Date(currentStartDate.getTime() - diff);
      prevEndDate = new Date(currentEndDate.getTime() - diff);
    } else {
      switch (period) {
        case 'TODAY':
          currentStartDate = new Date(today.getTime() - 1);
          currentEndDate = today;
          prevStartDate = new Date(currentStartDate.getTime() - 24 * 60 * 60 * 1000);
          prevEndDate = new Date(currentStartDate.getTime() - 1);
          break;

        case 'THIS_WEEK':
          currentStartDate = filter.THIS_WEEK;
          currentEndDate = today;
          prevStartDate = new Date(currentStartDate.getTime() - 7 * 24 * 60 * 60 * 1000);
          prevEndDate = new Date(currentStartDate.getTime() - 1);
          break;

        case 'THIS_MONTH':
          currentStartDate = filter.THIS_MONTH;
          currentEndDate = today;
          prevStartDate = new Date(currentStartDate.getTime() - 30 * 24 * 60 * 60 * 1000);
          prevEndDate = new Date(currentStartDate.getTime() - 1);
          break;

        case 'ALL_TIME':
          currentStartDate = filter.ALL_TIME;
          currentEndDate = today;
          prevStartDate = null;
          prevEndDate = null;
          break;

        default:
          prevStartDate = null;
          prevEndDate = null;
          break;
      }
    }

    return {
      startDate: formatDate(currentStartDate),
      endDate: formatDate(currentEndDate),
      prevStartDate: prevStartDate ? formatDate(prevStartDate) : null,
      prevEndDate: prevEndDate ? formatDate(prevEndDate) : null,
    };
  };

  useEffect(() => {
    if (!manualDateChange) {
      const { startDate, endDate } = calculateDateRange(activeTab);
      setSDate(startDate);
      setEDate(endDate);
      setManualDateChange(false);
    }
  }, [activeTab, manualDateChange]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setSDate(e.target.value);
    setManualDateChange(true);
  };

  const handleFilterClick = () => {
    setManualDateChange(false);
    setStartDate('');
    setEndDate('');
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setEDate(e.target.value);
    setManualDateChange(true);
  };

  const { prevStartDate, prevEndDate } = calculateDateRange(activeTab, startDate, endDate);

  const { data: previousData, loading: previousLoading } = useQuery(GET_PLATFORM_ANALYTICS, {
    variables: { period: activeTab, startDate: prevStartDate, endDate: prevEndDate },
  });
  const { data: previousMetrics, loading: previousMetricsLoading } = useQuery(GET_CORE_METRICS, {
    variables: { period: activeTab, startDate: prevStartDate, endDate: prevEndDate },
  });

  const { data, loading } = useQuery(GET_PLATFORM_ANALYTICS, {
    variables: { period: activeTab, endDate: endDate, startDate: startDate },
  });
  const { data: core_metrics, loading: loadingMetrics } = useQuery(GET_CORE_METRICS, {
    variables: { period: activeTab, endDate: endDate, startDate: startDate },
  });
  console.log('COREmetr', core_metrics?.adminGetCoreMetricAnalysis?.data);
  const {
    data: topUsersData,
    loading: topUsersLoading,
    error: topUsersError,
  } = useQuery(GET_TOP_USERS, {
    variables: {
      limit: pageLimit,
      offset: offset,
      period: activeTab,
      allUsers: userFilter.allUsers,
      endDate: endDate,
      startDate: startDate,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: false,
    onError: (error) => {
      console.error('Error fetching top users: ', error.message);
    },
  });
  const { data: trendingPlaylistData, loading: trendingPlaylistLoading } = useQuery(GET_TRENDING_PLAYLIST, {
    variables: { limit: 5 },
  });

  // reboot radio
  const [rebootTheRadio] = useMutation(RADIO_REBOOT);
  async function radioRebootOnClick() {
    const resp = await rebootTheRadio();
    if (resp?.data?.adminRadioColdReboot?.status === 'success') {
      showSuccessNotification(resp.data.adminRadioColdReboot.message);
    }

    if (resp?.data?.adminRadioColdReboot?.status === 'failed') {
      showErrorNotification(resp.data.adminRadioColdReboot.message);
    }
  }

  // TODO: pass in live data to this function
  function renderTabData() {
    function handleClear() {
      setStartDate('');
      setEndDate('');
    }

    return (
      <>
        <InputWrapper>
          <InputLabel>
            Start Date:
            <input type="date" value={sDate} onChange={handleStartDateChange} />
          </InputLabel>
          <InputLabel style={{ paddingRight: '0.5em' }}>
            End Date:
            <input type="date" value={eDate} onChange={handleEndDateChange} />
          </InputLabel>
          <InputButton onClick={handleClear}>Clear</InputButton>
        </InputWrapper>
        <AnalyticsWrapper>
          <AnalyticsCard colour={Colour.PrimaryDeep} padding={48}>
            <TextContent textAlign="center" display="block" fontSize={32} fontWeight="700" colour={Colour.White}>
              {loading ? (
                'Loading'
              ) : (
                <>
                  {numberWithCommas(data?.adminGetPlatformAnalytics?.data?.totalUsers || 0)}
                  {previousData && (
                    <>
                      &nbsp;
                      <GrowthArrow
                        trend={getGrowthTrend(
                          data?.adminGetPlatformAnalytics?.data?.totalUsers,
                          previousData?.adminGetPlatformAnalytics?.data?.totalUsers
                        )}
                      />
                    </>
                  )}
                </>
              )}
            </TextContent>
            <TextContent textAlign="center" display="block" fontSize={22} fontWeight="400" colour={Colour.Grey}>
              {previousLoading ? (
                'previousLoading'
              ) : (
                <>{numberWithCommas(previousData?.adminGetPlatformAnalytics?.data?.totalUsers || 0)}</>
              )}
            </TextContent>
            <TextContent textAlign="center" fontSize={14} colour={Colour.White} display="block">
              Total&nbsp;Users
            </TextContent>
          </AnalyticsCard>
          <AnalyticsCard colour={Colour.Primary} padding={48}>
            <TextContent textAlign="center" display="block" fontSize={32} fontWeight="700" colour={Colour.White}>
              {loading || previousLoading ? (
                'Loading'
              ) : (
                <>
                  {numberWithCommas(data?.adminGetPlatformAnalytics?.data?.totalGamesPlayed || 0)}
                  {previousData && (
                    <>
                      &nbsp;
                      <GrowthArrow
                        trend={getGrowthTrend(
                          data?.adminGetPlatformAnalytics?.data?.totalGamesPlayed,
                          previousData?.adminGetPlatformAnalytics?.data?.totalGamesPlayed
                        )}
                      />
                    </>
                  )}
                </>
              )}
            </TextContent>
            <TextContent textAlign="center" display="block" fontSize={22} fontWeight="400" colour={Colour.Grey}>
              {previousLoading ? (
                'previousLoading'
              ) : (
                <>{numberWithCommas(previousData?.adminGetPlatformAnalytics?.data?.totalGamesPlayed || 0)}</>
              )}
            </TextContent>
            <TextContent textAlign="center" fontSize={14} colour={Colour.White} display="block">
              Games
            </TextContent>
          </AnalyticsCard>
          <AnalyticsCard colour={Colour.GreyLight} padding={48}>
            <TextContent textAlign="center" display="block" fontSize={32} fontWeight="700" colour={Colour.White}>
              {loading || previousLoading ? (
                'Loading'
              ) : (
                <>
                  {numberWithCommas(data?.adminGetPlatformAnalytics?.data?.totalHosts || 0)}
                  {previousData && (
                    <>
                      &nbsp;
                      <GrowthArrow
                        trend={getGrowthTrend(
                          data?.adminGetPlatformAnalytics?.data?.totalHosts,
                          previousData?.adminGetPlatformAnalytics?.data?.totalHosts
                        )}
                      />
                    </>
                  )}
                </>
              )}
            </TextContent>
            <TextContent textAlign="center" display="block" fontSize={22} fontWeight="400" colour={Colour.Grey}>
              {previousLoading ? (
                'previousLoading'
              ) : (
                <>{numberWithCommas(previousData?.adminGetPlatformAnalytics?.data?.totalHosts || 0)}</>
              )}
            </TextContent>
            <TextContent textAlign="center" fontSize={14} colour={Colour.White} display="block">
              Hosts
            </TextContent>
          </AnalyticsCard>
          <AnalyticsCard colour={Colour.Black} padding={48}>
            <TextContent textAlign="center" display="block" fontSize={32} fontWeight="700" colour={Colour.White}>
              {loading || previousLoading ? (
                'Loading'
              ) : (
                <>
                  {numberWithCommas(data?.adminGetPlatformAnalytics?.data?.totalPlayer || 0)}
                  {previousData && (
                    <>
                      &nbsp;
                      <GrowthArrow
                        trend={getGrowthTrend(
                          data?.adminGetPlatformAnalytics?.data?.totalPlayer,
                          previousData?.adminGetPlatformAnalytics?.data?.totalPlayer
                        )}
                      />
                    </>
                  )}
                </>
              )}
              <TextContent textAlign="center" display="block" fontSize={22} fontWeight="400" colour={Colour.Grey}>
                {previousLoading ? (
                  'previousLoading'
                ) : (
                  <>{numberWithCommas(previousData?.adminGetPlatformAnalytics?.data?.totalPlayer || 0)}</>
                )}
              </TextContent>
            </TextContent>
            <TextContent textAlign="center" fontSize={14} colour={Colour.White} display="block">
              Players
            </TextContent>
          </AnalyticsCard>
        </AnalyticsWrapper>
        <AnalyticsWrapper>
          <AnalyticsCard colour={Colour.PrimaryDeep} padding={15}>
            <TextContent textAlign="center" display="block" fontSize={32} fontWeight="700" colour={Colour.White}>
              {loadingMetrics ? (
                '0'
              ) : (
                <>
                  {numberWithCommas(core_metrics?.adminGetCoreMetricAnalysis?.data?.creatorHost || 0)}
                  {previousMetrics && (
                    <>
                      &nbsp;
                      <GrowthArrow
                        trend={getGrowthTrend(
                          core_metrics?.adminGetCoreMetricAnalysis?.data?.creatorHost,
                          previousMetrics?.adminGetCoreMetricAnalysis?.data?.creatorHost
                        )}
                      />
                    </>
                  )}
                </>
              )}
            </TextContent>
            <TextContent textAlign="center" display="block" fontSize={22} fontWeight="400" colour={Colour.Grey}>
              {previousMetricsLoading ? (
                '0'
              ) : (
                <>{numberWithCommas(previousMetrics?.adminGetCoreMetricAnalysis?.data?.creatorHost || 0)}</>
              )}
            </TextContent>
            <TextContent textAlign="center" fontSize={14} colour={Colour.White} display="block">
              Creator Hosts
            </TextContent>
          </AnalyticsCard>
          <AnalyticsCard colour={Colour.Primary} padding={15}>
            <TextContent textAlign="center" display="block" fontSize={32} fontWeight="700" colour={Colour.White}>
              {loadingMetrics ? (
                '0'
              ) : (
                <>
                  {numberWithCommas(core_metrics?.adminGetCoreMetricAnalysis?.data?.primeHosts || 0)}
                  {previousMetrics && (
                    <>
                      &nbsp;
                      <GrowthArrow
                        trend={getGrowthTrend(
                          core_metrics?.adminGetCoreMetricAnalysis?.data?.primeHosts,
                          previousMetrics?.adminGetCoreMetricAnalysis?.data?.primeHosts
                        )}
                      />
                    </>
                  )}
                </>
              )}
            </TextContent>
            <TextContent textAlign="center" display="block" fontSize={22} fontWeight="400" colour={Colour.Grey}>
              {previousMetricsLoading ? (
                '0'
              ) : (
                <>{numberWithCommas(previousMetrics?.adminGetCoreMetricAnalysis?.data?.primeHosts || 0)}</>
              )}
            </TextContent>
            <TextContent textAlign="center" fontSize={14} colour={Colour.White} display="block">
              Prime Hosts
            </TextContent>
          </AnalyticsCard>
          <AnalyticsCard colour={Colour.GreyLight} padding={15}>
            <TextContent textAlign="center" display="block" fontSize={32} fontWeight="700" colour={Colour.White}>
              {previousMetricsLoading ? (
                '0'
              ) : (
                <>
                  {numberWithCommas(core_metrics?.adminGetCoreMetricAnalysis?.data?.newbies || 0)}
                  {previousMetrics && (
                    <>
                      &nbsp;
                      <GrowthArrow
                        trend={getGrowthTrend(
                          core_metrics?.adminGetCoreMetricAnalysis?.data?.newbies,
                          previousMetrics?.adminGetCoreMetricAnalysis?.data?.newbies
                        )}
                      />
                    </>
                  )}
                </>
              )}
            </TextContent>
            <TextContent textAlign="center" display="block" fontSize={22} fontWeight="400" colour={Colour.Grey}>
              {previousLoading ? (
                '0'
              ) : (
                <>{numberWithCommas(previousMetrics?.adminGetCoreMetricAnalysis?.data?.newbies || 0)}</>
              )}
            </TextContent>
            <TextContent textAlign="center" fontSize={14} colour={Colour.White} display="block">
              Noobs
            </TextContent>
          </AnalyticsCard>
          <AnalyticsCard colour={Colour.Black} padding={15}>
            <TextContent textAlign="center" display="block" fontSize={32} fontWeight="700" colour={Colour.White}>
              {previousMetricsLoading? (
                '0'
              ) : (
                <>
                  {numberWithCommas(core_metrics?.adminGetCoreMetricAnalysis?.data?.teamBrand || 0)}
                  {previousMetrics && (
                    <>
                      &nbsp;
                      <GrowthArrow
                        trend={getGrowthTrend(
                          core_metrics?.adminGetCoreMetricAnalysis?.data?.teamBrand,
                          previousMetrics?.adminGetCoreMetricAnalysis?.data?.teamBrand
                        )}
                      />
                    </>
                  )}
                </>
              )}
              <TextContent textAlign="center" display="block" fontSize={22} fontWeight="400" colour={Colour.Grey}>
                {previousMetricsLoading ? (
                  '0'
                ) : (
                  <>{numberWithCommas(previousMetrics?.adminGetCoreMetricAnalysis?.data?.teamBrand || 0)}</>
                )}
              </TextContent>
            </TextContent>
            <TextContent textAlign="center" fontSize={14} colour={Colour.White} display="block">
              Team Brands
            </TextContent>
          </AnalyticsCard>
          <AnalyticsCard colour={Colour.Rose} padding={15}>
            <TextContent textAlign="center" display="block" fontSize={32} fontWeight="700" colour={Colour.White}>
              {previousMetricsLoading ? (
                '0'
              ) : (
                <>
                  {numberWithCommas(core_metrics?.adminGetCoreMetricAnalysis?.data?.activeHosts || 0)}
                  {previousMetrics && (
                    <>
                      &nbsp;
                      <GrowthArrow
                        trend={getGrowthTrend(
                          core_metrics?.adminGetCoreMetricAnalysis?.data?.activeHosts,
                          previousMetrics?.adminGetCoreMetricAnalysis?.data?.activeHosts
                        )}
                      />
                    </>
                  )}
                </>
              )}
              <TextContent textAlign="center" display="block" fontSize={22} fontWeight="400" colour={Colour.Grey}>
                {previousMetricsLoading ? (
                  '0'
                ) : (
                  <>{numberWithCommas(previousMetrics?.adminGetCoreMetricAnalysis?.data?.activeHosts || 0)}</>
                )}
              </TextContent>
            </TextContent>
            <TextContent textAlign="center" fontSize={14} colour={Colour.White} display="block">
              Active Hosts
            </TextContent>
          </AnalyticsCard>
          <AnalyticsCard colour={Colour.DeepGrey} padding={15}>
            <TextContent textAlign="center" display="block" fontSize={32} fontWeight="700" colour={Colour.White}>
              {previousMetricsLoading ? (
                '0'
              ) : (
                <>
                  {numberWithCommas(core_metrics?.adminGetCoreMetricAnalysis?.data?.activeNewbies || 0)}
                  {previousData && (
                    <>
                      &nbsp;
                      <GrowthArrow
                        trend={getGrowthTrend(
                          core_metrics?.adminGetCoreMetricAnalysis?.data?.activeNewbies,
                          previousMetrics?.adminGetCoreMetricAnalysis?.data?.activeNewbies
                        )}
                      />
                    </>
                  )}
                </>
              )}
              <TextContent textAlign="center" display="block" fontSize={22} fontWeight="400" colour={Colour.Grey}>
                {previousLoading ? (
                  '0'
                ) : (
                  <>{numberWithCommas(previousMetrics?.adminGetCoreMetricAnalysis?.data?.activeNewbies || 0)}</>
                )}
              </TextContent>
            </TextContent>
            <TextContent textAlign="center" fontSize={14} colour={Colour.White} display="block">
              Active Noobs
            </TextContent>
          </AnalyticsCard>
        </AnalyticsWrapper>
        <SectionTitle>
          <TextContent fontSize={24} fontWeight="700" colour={Colour.Primary}>
            Top Users
          </TextContent>
          <Link to={routes.users}>
            <Button variant="black">
              <TextContent fontSize={14} colour={Colour.White}>
                All Users
              </TextContent>
            </Button>
          </Link>
        </SectionTitle>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '20px',
          }}
        >
          <div style={{ marginRight: '15px' }}>
            <input
              style={{ scale: 1.3 }}
              type="checkbox"
              checked={userFilter.allUsers}
              onChange={() =>
                setUserFilter((prevState) => {
                  return {
                    allUsers: !prevState.allUsers,
                    registeredUsers: !prevState.registeredUsers,
                  };
                })
              }
            />
            &nbsp; All Users{' '}
          </div>
          <div>
            <input
              style={{ scale: 1.3 }}
              type="checkbox"
              checked={userFilter.registeredUsers}
              onChange={() => {
                setUserFilter((prevState) => {
                  return {
                    registeredUsers: !prevState.registeredUsers,
                    allUsers: !prevState.allUsers,
                  };
                });
                returnPaginationToDefault();
              }}
            />
            &nbsp; Registered Users{' '}
          </div>
        </div>
        <div className='tWidth'>
        <Table
          loading={topUsersLoading}
          data={topUsersData?.adminGetTopPlayers?.data || []}
          columns={columns}
          page={page}
          limit={pageLimit}
          updatePageLimit={updatePageLimit}
          total={topUsersData?.adminGetTopPlayers?.totalCount || 1}
          showPagination
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
          scroll={{x: 2600, y : 450}}
        />
        </div>
        {(trendingPlaylistData?.getTrendingPlaylist?.data.length || trendingPlaylistLoading) && (
          <SectionTitle>
            <TextContent fontSize={24} fontWeight="700" colour={Colour.Primary}>
              Trending Playlists
            </TextContent>
            <Link to={routes.playlist}>
              <Button variant="black">
                <TextContent fontSize={14} colour={Colour.White}>
                  All Playlists
                </TextContent>
              </Button>
            </Link>
          </SectionTitle>
        )}
        {trendingPlaylistLoading && (
          <TextContent fontSize={24} colour={Colour.Primary} fontWeight="bold">
            Loading
          </TextContent>
        )}
        {trendingPlaylistData?.getTrendingPlaylist?.data && (
          <PlaylistImageWrapper>
            {trendingPlaylistData?.getTrendingPlaylist?.data.map((playlist) => (
              <PlaylistImage key={playlist.title} src={playlist.imageUrl} alt={playlist.title} />
            ))}
          </PlaylistImageWrapper>
        )}
      </>
    );
  }

  return (
    <div>
      <PageTitle
        title={`Hello ${authContextProps.user?.fullname}`}
        rightComponent={
          <Button onClick={radioRebootOnClick}>
            <TextContent fontSize={14} fontWeight="600" colour={Colour.White}>
              Radio Reboot
            </TextContent>
          </Button>
        }
      />
      <Tab activeTab={activeTab} items={TabItems} onTabSelect={setActiveTab} onFilterClick={handleFilterClick} />
      {renderTabData()}
    </div>
  );
}
