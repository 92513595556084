import React, { useEffect } from 'react';
import TextContent from '../../components/textContent';
import Tab from '../../components/tab';
import styled from 'styled-components';
import Colour from '../../lib/colour';
import Table from '../../components/table';
import PageTitle from '../../components/pageTitle';
import { GET_LEADERBOARD } from '../../lib/graphQl/query';
import { useQuery } from '@apollo/client';
import TableTools from '../../components/tableTools';
import { DateTime } from 'luxon';

const Item = styled.span`
  font-size: 16px;
  margin-left: 10px;
  color: ${Colour.BlackText};
`;

const TabItems = [
  { name: 'Today', key: 'TODAY' },
  { name: 'This Week', key: 'THIS_WEEK' },
  { name: 'This Month', key: 'THIS_MONTH' },
  { name: 'All Time', key: 'ALL_TIME' },
];

const pageLimit = 10;
export default function LeaderBoard() {
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [pageLimit, setPageLimit] = React.useState(10);
  const [activeTab, setActiveTab] = React.useState('THIS_MONTH');
  const [searchQuery, setSearchQuery] = React.useState('');
  // const [sortColumn, setSortColumn] = React.useState('accuracy');
  // const [sortOrder, setSortOrder] = React.useState('DESCENDING');
  // const [accuracyFilter, setAccuracyFilter] = React.useState([]);
  // const [allData, setAllData] = React.useState([]);
  // const [filteredData, setFilteredData] = React.useState([]);
  const [sortValue, setSortValue] = React.useState({
    label: 'Date-Descending',
    value: 'date_descending',
    sort: 'DESCENDING',
    type: 'DATE',
  });

  const { data, loading, refetch } = useQuery(GET_LEADERBOARD, {
    variables: {
      limit: pageLimit,
      offset: offset,
      period: activeTab,
      nameLike: searchQuery,
      // sortColumn: 'accuracy',
      sortType: sortValue.type,
      sortOrder: sortValue.sort,
      // accuracyFilter: accuracyFilter.filter((filter) => filter.checked).map((filter) => parseFloat(filter.value)),
    },
  });

  // useEffect(() => {
  //   const storedFilters = JSON.parse(localStorage.getItem('accuracyFilters')) || [];

  //   if (data && data.adminGetLeaderBoard) {
  //     const uniqueAccuracies = [...new Set(data.adminGetLeaderBoard.data.map((item) => item.accuracy))];

  //     // Update state in a single operation to avoid multiple renders
  //     const newAccuracyFilter = uniqueAccuracies.map((acc) => ({
  //       value: acc,
  //       label: `${acc}%`,
  //       checked: accuracyFilter.find((filter) => filter.value === acc)?.checked || false,
  //     }));

  //     const updatedAccuracyFilter = [
  //       ...newAccuracyFilter,
  //       ...storedFilters.filter((filter) => !uniqueAccuracies.includes(filter.value)),
  //     ];

  //     // Use React's batching to avoid unnecessary re-renders
  //     localStorage.setItem('accuracyFilters', JSON.stringify(updatedAccuracyFilter));
  //     setAccuracyFilter(updatedAccuracyFilter);
  //     setAllData(data.adminGetLeaderBoard.data);
  //     setFilteredData(data.adminGetLeaderBoard.data);
  //   }
  // }, [data]);

  useEffect(() => {
    refetch();
  }, [refetch, pageLimit, offset, activeTab, searchQuery]);

  const getBestTimes = (data, activeTab) => {
   const leaderboardData = data?.adminGetLeaderBoard?.data || [];
   if (leaderboardData.length === 0) return {};

    // Filter data based on activeTab (time filter)
    const filteredData =
      activeTab === 'ALL_TIME'
        ? leaderboardData
        : leaderboardData
            .filter((item) => {
              const gameCompletedDateTime = new Date(item.gameCompletedDateTime);
              const today = new Date();
              const past24Hours = new Date(today.getTime() - 24 * 60 * 60 * 1000);
              const startOfPast30Days = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000); // Past 30 days

              switch (activeTab) {
                case 'TODAY':
                  return gameCompletedDateTime >= past24Hours;
                case 'THIS_WEEK':
                  return gameCompletedDateTime.getTime() >= today.getTime() - 7 * 24 * 60 * 60 * 1000;
                case 'THIS_MONTH':
                  return gameCompletedDateTime >= startOfPast30Days;
                default:
                  return true; // All Time (no filtering)
              }
            })
            .filter((item) => item.numberOfRounds >= 4);

    if (leaderboardData.length === 0) {
      return {
        bestRoundTime: null,
        bestWarmUpTime: null,
        bestAverageTimePerRound: null,
      };
    }

      const currentPageData = leaderboardData.slice(offset, offset + pageLimit);

    // Find the player with the minimum values for each property
   const bestRoundTimeItem = currentPageData.reduce(
     (minItem, item) => {
       // Exclude zero values and compare
       if (item.bestRoundTime === 0) return minItem;
       return item.bestRoundTime < minItem.bestRoundTime && minItem.bestRoundTime !== 0 ? item : minItem;
     },
     currentPageData[0] || {} // Provide initial value to avoid error
   );

   const bestWarmUpTimeItem = currentPageData.reduce(
     (minItem, item) => {
       // Exclude zero values and compare
       if (item.bestWarmUpTime === 0) return minItem;
       return item.bestWarmUpTime < minItem.bestWarmUpTime && minItem.bestWarmUpTime !== 0 ? item : minItem;
     },
     currentPageData[0] || {} // Provide initial value to avoid error
   );

    const bestAverageTimePerRoundItem = currentPageData.reduce(
      (minItem, item) => (item.bestAverageTimePerRound < minItem.bestAverageTimePerRound ? item : minItem),
      currentPageData[0] || {} // Provide initial value
    );

    return {
      bestRoundTime: bestRoundTimeItem,
      bestWarmUpTime: bestWarmUpTimeItem,
      bestAverageTimePerRound: bestAverageTimePerRoundItem,
    };
  };

  const bestTimes = getBestTimes(data, activeTab);

  // useEffect(() => {
  //   function handleVisibilityChange() {
  //     if (document.visibilityState === 'hidden') {
  //       localStorage.removeItem('accuracyFilters');
  //     }
  //   }

  //   document.addEventListener('visibilitychange', handleVisibilityChange);

  //   // Cleanup the event listener
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, []);

  function updatePageLimit() {
    if (pageLimit === 10) {
      setPageLimit(20);
    } else if (pageLimit === 20) {
      setPageLimit(50);
    } else if (pageLimit === 50) {
      setPageLimit(100);
    } else {
      setPageLimit(10);
    }
  }

  function returnPaginationToDefault() {
    setOffset(0);
    setPage(1);
  }

  useEffect(() => {
    returnPaginationToDefault();
  }, [activeTab]);

//  function onPrevPage() {
//    setOffset((page - 2) * pageLimit);
//    setPage((prevPage) => prevPage - 1);
//  }

//  function onNextPage() {
//    setOffset(page * pageLimit);
//    setPage((prevPage) => prevPage + 1);
//  }

  function onPrevPage() {
    setPage((prevPage) => Math.max(prevPage - 1, 1)); // Prevent going below page 1
  }

  function onNextPage() {
    setPage((prevPage) => prevPage + 1); // Assuming you handle data availability for next page elsewhere
  }

 function onSortChange(newSortValue) {
   setSortValue({ ...newSortValue });
 }


  // function handleAccuracyChange(event) {
  //   const { name, checked } = event.target;
  //   const updatedFilters = accuracyFilter.map((filter) =>
  //     filter.value.toString() === name ? { ...filter, checked } : filter
  //   );
  //   setAccuracyFilter(updatedFilters);

  //   // Update the filteredData based on checked filters
  //   const selectedFilters = updatedFilters.filter((filter) => filter.checked).map((filter) => parseFloat(filter.value));

  //   setFilteredData(
  //     selectedFilters.length === 0 ? allData : allData.filter((item) => selectedFilters.includes(item.accuracy))
  //   );

  //   // Update local storage with the new filter state
  //   localStorage.setItem('accuracyFilters', JSON.stringify(updatedFilters));

  //   returnPaginationToDefault();
  // }

  const columns = [
    {
      title: 'GAME CODE',
      dataIndex: 'gameCode',
      align: 'center',
      fixed: 'left',
      width: 150,
      render: (gameCode) => (
        <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
          {gameCode}
        </TextContent>
      ),
    },
    {
      title: 'HOST',
      dataIndex: 'hostName',
      width: 150,
      align: 'center',
      render: (hostName) => (
        <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
          {hostName}
        </TextContent>
      ),
    },
    {
      title: 'PLAYLIST',
      dataIndex: 'playlistName',
      align: 'center',
      width: 150,
      render: (playlistName) => (
        <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
          {playlistName}
        </TextContent>
      ),
    },
    {
      title: 'PLAYER',
      dataIndex: 'playerName',
      align: 'center',
      width: 150,
      render: (playerName) => (
        <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
          {playerName}
        </TextContent>
      ),
    },
    {
      title: 'ACCURACY',
      dataIndex: 'accuracy',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.accuracy - b.accuracy,
      render: (accuracy) =>
        accuracy ? (
          <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
            {accuracy}
          </TextContent>
        ) : (
          5
        ),
    },
    {
      title: 'NO OF ROUNDS',
      dataIndex: 'numberOfRounds',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.numberOfRounds - b.numberOfRounds,
      render: (numberOfRounds) => (
        <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
          {numberOfRounds}
        </TextContent>
      ),
    },
    {
      title: 'AVERAGE TIME PERROUND',
      dataIndex: 'averageTimePerRound',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.averageTimePerRound - b.averageTimePerRound,
      // fixed: 'right',
      render: (averageTimePerRound) => (
        <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
          {averageTimePerRound}
        </TextContent>
      ),
    },
    {
      title: 'TOTAL TIME FOR ALLROUNDS',
      dataIndex: 'totalTimeForAllRounds',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.totalTimeForAllRounds - b.totalTimeForAllRounds,
      // fixed: 'right',
      render: (totalTimeForAllRounds) => (
        <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
          {totalTimeForAllRounds.toFixed(2)}
        </TextContent>
      ),
    },
    {
      title: 'GAME COMPLETED TIME',
      dataIndex: 'gameCompletedDateTime',
      width: 150,
      sorter: (a, b) =>
        DateTime.fromISO(a.gameCompletedDateTime).toMillis() - DateTime.fromISO(b.gameCompletedDateTime).toMillis(),
      // fixed: 'right',
      render: (time) =>
        time ? (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {DateTime.fromISO(time).toFormat('dd-MM-y')}{' '}
            <TextContent fontSize={10} colour={Colour.Grey}>
              {DateTime.fromISO(time).toFormat('HH:mm')}
            </TextContent>
          </TextContent>
        ) : (
          'N/A'
        ),
    },
  ];

  return (
    <>
      <PageTitle title="LEADER BOARD" />
      <TableTools
        onSearch={setSearchQuery}
        searchPlaceholder="Enter a search term..."
        searchValue={searchQuery}
        sortValue={sortValue}
        onSortSelect={onSortChange}
        canSearch
        canSort
      />
      <Tab activeTab={activeTab} items={TabItems} onTabSelect={setActiveTab} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '10px',
          marginBottom: '20px',
        }}
      >
        {/* {accuracyFilter.map((filter) => (
          <div key={filter.value} style={{ marginRight: '15px' }}>
            <input
              style={{ transform: 'scale(1.3)' }}
              type="checkbox"
              name={filter.value.toString()}
              checked={filter.checked}
              onChange={handleAccuracyChange}
            />
            &nbsp; {filter.label}{' '}
          </div>
        ))} */}
      </div>

      {/* Display the Best Times */}
      <div style={{ marginBottom: '20px', padding: '10px', backgroundColor: Colour.LightGrey }}>
        <div>
          <TextContent fontSize={16} fontWeight="600" colour={Colour.BlackText} style={{ display: 'block' }}>
            Best Warm Up Time:
          </TextContent>
          <Item>
            {bestTimes
              ? `${bestTimes.bestWarmUpTime?.bestWarmUpTime}s (Game code: ${
                  bestTimes.bestWarmUpTime?.gameCode || 'N/A'
                }) (Player: ${bestTimes.bestWarmUpTime?.playerName || `N/A`})`
              : 'N/A'}
          </Item>
        </div>
        <div>
          <TextContent fontSize={16} fontWeight="600" colour={Colour.BlackText} style={{ display: 'block' }}>
            Best Round Time:
          </TextContent>
          <Item>
            {bestTimes
              ? `${bestTimes.bestRoundTime?.bestRoundTime}s (Game code: ${
                  bestTimes.bestRoundTime?.gameCode || 'N/A'
                }) (Player: ${bestTimes.bestRoundTime?.playerName || `N/A`})`
              : 'N/A'}
          </Item>
        </div>
        <div>
          <TextContent fontSize={16} fontWeight="600" colour={Colour.BlackText} style={{ display: 'block' }}>
            Best Average Time Per Round:
          </TextContent>
          <Item>
            {bestTimes
              ? `${bestTimes.bestAverageTimePerRound?.bestAverageTimePerRound}s Game code: ${
                  bestTimes.bestAverageTimePerRound?.gameCode || `N/A`
                } (Player: ${bestTimes.bestAverageTimePerRound?.playerName || `N/A`})`
              : 'N/A'}
          </Item>
        </div>
      </div>

      <Table
        columns={columns}
        // data={filteredData}
        data={data?.adminGetLeaderBoard?.data || []}
        loading={loading}
        limit={pageLimit}
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        page={page}
        showPagination
        updatePageLimit={updatePageLimit}
        total={data?.adminGetLeaderBoard?.totalCount || 1}
        scroll={{ x: 2000, y: 540 }}
      />
    </>
  );
}
