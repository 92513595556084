import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import Button from '../../components/button';
import NoftTriggerForm from '../../components/noft-trigger-form';
import { notificationTriggers } from '../../utils/constant';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_BANNER, NEW_FEATURE_NOTIFICATION, PART_INVITATION, SEND_GAME_TIP, UPDATE_BANNER_STATUS } from '../../lib/graphQl/mutation';


const TriggerList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

function Notifications() {
  const [selected, setSelected] = useState('');
  const [title, setTitle] = useState('');
  const [newFeature] = useMutation(NEW_FEATURE_NOTIFICATION);
  const [partInvite] = useMutation(PART_INVITATION);
  const [sendTip] = useMutation(SEND_GAME_TIP);
  const [createBanner] = useMutation(CREATE_BANNER);
  const [updateBanner] = useMutation(UPDATE_BANNER_STATUS);

  const clickHandler = (type, content) => {
    setSelected(type);
    setTitle(content);
  };

  const hideModal = useCallback(() => {
    if (title || selected) {
      setSelected('');
      setTitle('');
    }
  }, [title, selected]);

  const formProps = useMemo(() => {
    return {
      graphReq:
        selected === 'notifyOfNewFeature'
          ? newFeature
          : selected === 'partyInvitation'
          ? partInvite
          : selected === 'sendGameTip'
          ? sendTip
          : selected === 'createBanner'
          ? createBanner
          : selected === 'updateBannerStatus'
          ? updateBanner
          : async () => {},
      noftTitle: title,
      typeofNoft: selected,
      inputName:
        selected === 'notifyOfNewFeature'
          ? 'new_feature'
          : selected === 'partyInvitation'
          ? 'party_invite'
          : selected === 'sendGameTip'
          ? 'tip_title'
          : selected === 'createBanner'
          ? 'banner_title'
          : selected === 'updateBannerStatus'
          ? 'banner_title'
          : '',
      inputLabel:
        selected === 'notifyOfNewFeature'
          ? 'New feature'
          : selected === 'partyInvitation'
          ? 'Party invite details'
          : selected === 'sendGameTip'
          ? 'Game tip title'
          : selected === 'createBanner'
          ? 'Banner title'
          : selected === 'updateBannerStatus'
          ? ''
          : '',
      inputPlaceholder:
        selected === 'notifyOfNewFeature'
          ? 'Enter feature name'
          : selected === 'partyInvitation'
          ? 'Enter invite details'
          : selected === 'sendGameTip'
          ? 'Enter tip title'
          : selected === 'createBanner'
          ? ''
          : '',
    };
  }, [selected, title]);

  return (
    <section>
      <section>
        <TriggerList>
          {notificationTriggers.map((item) => {
            return (
              <li key={item.type}>
                <Button
                  onClick={(e) => {
                    clickHandler(item.type, e.target.textContent);
                  }}
                >
                  {item.title}
                </Button>
              </li>
            );
          })}
        </TriggerList>
      </section>
      <NoftTriggerForm selected={!!selected} {...formProps} cancel={hideModal} />
    </section>
  );
}

export default Notifications;
