export const notifyPermission = async () => {
  if (!('Notification' in window)) {
    throw new Error('Notification not supported');
  } else if (Notification.permission === 'granted') {
    return;
  } else if (Notification.permission !== 'granted') {
    await requestPermission();
    return;
  }
};

const requestPermission = async () => {
  return await Notification.requestPermission()
    .then(async (permission) => {
      if (permission === 'granted') {
        return true;
      } else {
        throw new Error('Permission not granted for Notification');
      }
    })
    .catch((err) => {
      throw new Error(err.message);
    });
};
