import React from 'react';
import { Checkbox as RCheckbox } from 'antd';
import TextContent from '../textContent';
import PropTypes from 'prop-types';

export default function Checkbox(props) {
  function onChange(event) {
    if (props.onChange && typeof props.onChange === 'function') {
        props.onChange(event.target.checked);
    }
}

  return (
    <RCheckbox checked={props.isChecked} onChange={onChange}>
      <TextContent fontSize={14}>{props.label}</TextContent>
    </RCheckbox>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  selectedRow: PropTypes.array,
};
