import React, { useEffect, useState } from 'react';
import './TogglerButton.css';
export default function TogglerButton({checked}) {

   const [isToggled, setIsToggled] = useState(true);

   const handleChange = () => {
     setIsToggled(!isToggled);
   };
   useEffect(() => {
     setIsToggled(checked);
   }, [checked]);

  
  return (
    <label className="switch">
      <input type="checkbox" checked={isToggled} onChange={handleChange} />
      <span className="slider round"></span>
    </label>
  );
}
