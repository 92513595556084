import React from 'react';
import { Table as RTable } from 'antd';
import PropTypes from 'prop-types';
import TextContent from '../textContent';
import Button from '../button';
import Colour from '../../lib/colour';
import styled from 'styled-components';

const PaginationContainer = styled.div`
  padding: 8px 0px;
  border-top: 1px solid ${Colour.DeepGrey};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0px;

  > div > button:first-child {
    margin-right: 12px;
  }
`;

export default function Table(props) {
  const isNextDisabled = props.showPagination && props.page >= Math.ceil(props.total / props.limit);
  const isPrevDisabled = props.showPagination && props.page <= 1;

  return (
    <div>
      <RTable
        rowKey="id"
        rowSelection={
          props.canSelectRows
            ? {
                selectedRowKeys: props.selectedRow,
                onChange: props.onSelectedRowChange,
              }
            : undefined
        }
        columns={props.columns}
        dataSource={props.data}
        pagination={false}
        loading={props.loading}
        scroll={props.scroll}
      />
      {props.updatePageLimit && (
        <div
          style={{
            marginTop: 10,
            padding: 5,
            width: 50,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            borderRadius: 5,
            backgroundColor: 'blue',
            color: '#f2f2f2',
            cursor: 'pointer',
          }}
          onClick={() => props.updatePageLimit()}
        >
          {props.limit}x
        </div>
      )}
      {props.showPagination && (
        <PaginationContainer>
          <TextContent fontSize={14} fontWeight="500" colour={Colour.TextGrey}>
            {`Showing ${(props.page - 1) * props.limit + 1} to ${
              props.limit * props.page > props.total ? props.total : props.limit * props.page
            } of ${props.total} results`}
          </TextContent>
          <div>
            <Button state="outline" shape="rectangle" onClick={props.onPrevPage} disabled={isPrevDisabled}>
              <TextContent fontSize={14} fontWeight="500" colour={Colour.TextGrey}>
                Previous
              </TextContent>
            </Button>
            <Button state="outline" shape="rectangle" onClick={props.onNextPage} disabled={isNextDisabled}>
              <TextContent fontSize={14} fontWeight="500" colour={Colour.TextGrey}>
                Next
              </TextContent>
            </Button>
          </div>
        </PaginationContainer>
      )}
    </div>
  );
}

Table.propTypes = {
  showPagination: PropTypes.bool,
  canSelectRows: PropTypes.bool,
  selectedRow: PropTypes.array,
  loading: PropTypes.bool,
  page: PropTypes.number,
  limit: PropTypes.number,
  total: PropTypes.number,
  onPrevPage: PropTypes.func,
  onNextPage: PropTypes.func,
  onSelectedRowChange: PropTypes.func,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  scroll: PropTypes.object,
};
