import Colour from '../../lib/colour';
import React from 'react';
import styled from 'styled-components';
import TextContent from '../textContent';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  margin-bottom: 32px;
`;

const TabLabel = styled.span`
  padding: 8px 12px;
  border-radius: 6px;
  background-color: ${(props) => (props.isActive ? Colour.PrimaryFade : 'transparent')};
  margin-right: 32px;
  cursor: pointer;
`;

export default function Tab(props) {
  return (
    <Wrapper>
      {props.items.map((item) => {
        const onClick = () => {
          props.onTabSelect(item.key);
          if (props.onFilterClick) {
            props.onFilterClick();
          }
        };
        const isActive = props.activeTab === item.key;

        return (
          <TabLabel key={item.key} onClick={onClick} isActive={isActive}>
            <TextContent fontSize={14} fontWeight="500" colour={isActive ? Colour.Primary : Colour.TextGrey}>
              {item.name}
            </TextContent>
          </TabLabel>
        );
      })}
    </Wrapper>
  );
}

Tab.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      key: PropTypes.string,
    })
  ).isRequired,
  activeTab: PropTypes.string,
  onTabSelect: PropTypes.func.isRequired,
  onFilterClick: PropTypes.func, // New prop type
};
