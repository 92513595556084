import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { initialize, messaging } from '../../utils/firebase.js';
import { SET_FIREBASE_TOKEN } from '../../lib/graphQl/query';
import { notifyPermission } from '../../utils/notification';
import { showSuccessNotification, showErrorNotification } from '../../lib/notificationManager';

const Notification = ({ user }) => {
  // const [notification, setNotification] = useState({ title: '', body: '' });
  // const notify = () => showSuccessNotification(<ToastBody />, <ToastTitle />);

  // function ToastTitle() {
  //   return <h4>{notification?.title}</h4>;
  // }

  // function ToastBody() {
  //   return <p>{notification?.body}</p>;
  // }

  const [setToken] = useMutation(SET_FIREBASE_TOKEN);

  useEffect(() => {
    (async function () {
      try {
        await notifyPermission('Hey hey welcome');
        const token = await initialize();

        console.log({ messaging });

        if (window.location.pathname.includes('/dashboard')) {
          await setToken({ variables: { token } });
        }
      } catch (err) {
        showErrorNotification(err.message);
      }
    })();
  }, []);

  // useEffect(() => {
  //   if (notification?.title) {
  //     notify();
  //   }

  //   return () => {};
  // }, [notification]);

  return null;
};

export default Notification;
