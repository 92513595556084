import React from 'react';
import Table from '../../components/table';
import TextContent from '../../components/textContent';
import Colour from '../../lib/colour';
import PageTitle from '../../components/pageTitle';
import TableTools from '../../components/tableTools';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_GAMES } from '../../lib/graphQl/query';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { generateParamUrl, routes } from '../../components/navigation/routes';
import { Link } from 'react-router-dom';

const Container = styled.div`
  > div:first-child {
    flex-basis: 40%;

    > div {
      margin-bottom: 32px;
    }
  }

  > div:last-child {
    flex-basis: 60%;
    margin-left: 64px;
  }
`;
const pageLimit = 10;
export default function Games() {
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [pageLimit, setPageLimit] = React.useState(10);
  const [searchQuery, setSearchQuery] = React.useState(null);
  const [sortValue, setSortValue] = React.useState({
    label: 'Date-Descending',
    value: 'date_descending',
    sort: 'DESCENDING',
    type: 'DATE',
  });
  const [filterNpc, setFilterNpc] = React.useState({
    humanGames: true,
    completedGames: false,
    multiPlayerOnly: false,
  });

  const { data, loading, refetch } = useQuery(GET_GAMES, {
    variables: {
      limit: pageLimit,
      offset: offset,
      nameLike: searchQuery,
      filterNpc: filterNpc.humanGames,
      filterCompleted: filterNpc.completedGames,
      filterPlayers: filterNpc.multiPlayerOnly,
      sortType: sortValue.type,
      sortOrder: sortValue.sort,
    },
  });

  React.useEffect(() => {
    refetch();
  }, [filterNpc, refetch]);
  function updatePageLimit() {
    if (pageLimit === 10) {
      setPageLimit(20);
    } else if (pageLimit === 20) {
      setPageLimit(50);
    } else if (pageLimit === 50) {
      setPageLimit(100);
    } else {
      setPageLimit(10);
    }
  }

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'gameTitle',
      align: 'center',
      width: 70,
      render: (gameTitle, record) => (
        <Link to={generateParamUrl(routes.viewGame, record.id)}>
          <div>
            <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
              {gameTitle}
            </TextContent>
          </div>
        </Link>
      ),
    },
    {
      title: 'HOST',
      dataIndex: 'host',
      align: 'center',
      width: 150,
      render: (host, record) => (
        <Link to={generateParamUrl(routes.viewGame, record.id)}>
          <div>
            <TextContent fontSize={14} fontWeight="500" colour={Colour.BlackText}>
              {host.fullname}
            </TextContent>
          </div>
        </Link>
      ),
    },
    {
      title: 'GAME CODE',
      width: 150,
      dataIndex: 'gameCode',
      render: (text) => <TextContent>{text}</TextContent>,
    },
    {
      title: 'TYPE',
      dataIndex: 'gameType',
      width: 150,
      align: 'center',
      render: (text) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {text}
        </TextContent>
      ),
    },
    {
      title: 'GAME MODE',
      dataIndex: 'gameMode',
      width: 170,
      align: 'center',
      sorter: (a, b) => a.gameMode.localeCompare(b.gameMode),
      render: (text) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {text}
        </TextContent>
      ),
    },
    {
      title: 'WINNING PATTERN',
      dataIndex: 'winningPattern',
      width: 170,
      align: 'center',
      render: (text) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {text}
        </TextContent>
      ),
    },
    {
      title: 'PLAYLIST',
      dataIndex: 'playlist',
      width: 150,
      align: 'center',
      render: (playlist) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {playlist ? playlist.title : ""}
        </TextContent>
      ),
    },
    {
      title: 'PRIZE',
      dataIndex: 'prize',
      width: 150,
      align: 'center',
      render: (prize) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {prize ? 'True' : 'false'}
        </TextContent>
      ),
    },
    {
      title: 'NO OF PLAYERS',
      dataIndex: 'players',
      width: 150,
      align: 'center',
      sorter: (a, b) => a.players.length - b.players.length,
      render: (players) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {players.length}
        </TextContent>
      ),
    },
    {
      title: 'CREATEDAT',
      dataIndex: 'createdAt',
      sorter: (a, b) =>
        (DateTime.fromISO(a.createdAt).toMillis() || 0) - (DateTime.fromISO(b.createdAt).toMillis() || 0),
      width: 170,
      render: (time) =>
        time ? (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {DateTime.fromISO(time).toFormat('dd-MM-y')}{' '}
            <TextContent fontSize={10} colour={Colour.Grey}>
              {DateTime.fromISO(time).toFormat('HH:mm')}
            </TextContent>
          </TextContent>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'GAME STARTED',
      dataIndex: 'gameStarted',
      align: 'center',
      width: 150,
      render: (gameStarted) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {gameStarted ? 'True' : 'False'}
        </TextContent>
      ),
    },
    {
      title: 'GAME STARTED DATE',
      dataIndex: 'gameStartedDateTime',
      sorter: (a, b) =>
        (DateTime.fromISO(a.gameStartedDateTime).toMillis() || 0) -
        (DateTime.fromISO(b.gameStartedDateTime).toMillis() || 0),
      width: 170,
      render: (time) =>
        time ? (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {DateTime.fromISO(time).toFormat('dd-MM-y')}{' '}
            <TextContent fontSize={10} colour={Colour.Grey}>
              {DateTime.fromISO(time).toFormat('HH:mm')}
            </TextContent>
          </TextContent>
        ) : (
          'N/A'
        ),
    },
    {
      title: 'GAME COMPLETED',
      dataIndex: 'gameCompleted',
      align: 'center',
      width: 150,
      sorter: (a, b) => a.gameCompleted - b.gameCompleted,
      render: (gameCompleted) => (
        <TextContent fontSize={14} colour={Colour.BlackText}>
          {gameCompleted ? 'True' : 'False'}
        </TextContent>
      ),
    },
    {
      title: 'GAME COMPLETED DATE',
      dataIndex: 'gameCompletedDateTime',
      sorter: (a, b) =>
        (DateTime.fromISO(a.gameCompletedDateTime).toMillis() || 0) -
        (DateTime.fromISO(b.gameCompletedDateTime).toMillis() || 0),
      width: 170,
      render: (time) =>
        time ? (
          <TextContent fontSize={14} colour={Colour.BlackText}>
            {DateTime.fromISO(time).toFormat('dd-MM-y')}{' '}
            <TextContent fontSize={10} colour={Colour.Grey}>
              {DateTime.fromISO(time).toFormat('HH:mm')}
            </TextContent>
          </TextContent>
        ) : (
          'N/A'
        ),
    },
  ];

  function onPrevPage() {
    setOffset((page - 2) * pageLimit);
    setPage((prevPage) => prevPage - 1);
  }

  function onNextPage() {
    setOffset(page * pageLimit);
    setPage((prevPage) => prevPage + 1);
  }

  function onSortChange(newSortValue) {
    setSortValue({ ...newSortValue });
  }
  function returnPaginationToDefault() {
    setOffset(0);
    setPage(1);
  }

  const handleChange = (filterName) => {
    setFilterNpc((prevState) => {
      const newFilter = {
        ...prevState,
        [filterName]: !prevState[filterName],
      };
      returnPaginationToDefault();
      return newFilter;
    });
  };

  return (
    <div>
      <PageTitle title="Games" />
      <TableTools
        onSearch={setSearchQuery}
        searchPlaceholder="Enter a search term..."
        searchValue={searchQuery}
        sortValue={sortValue}
        onSortSelect={onSortChange}
        canSearch
        canSort
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '10px',
          marginBottom: '20px',
        }}
      >
        <div style={{ marginRight: '15px' }}>
          <input
            style={{ transform: 'scale(1.3)' }}
            type="checkbox"
            checked={filterNpc.humanGames}
            onChange={() => handleChange('humanGames')}
          />
          &nbsp; human Games{' '}
        </div>
        <div style={{ marginRight: '15px' }}>
          <input
            style={{ transform: 'scale(1.3)' }}
            type="checkbox"
            checked={filterNpc.completedGames}
            onChange={() => handleChange('completedGames')}
          />
          &nbsp; Completed Games{' '}
        </div>
        <div>
          <input
            style={{ transform: 'scale(1.3)' }}
            type="checkbox"
            checked={filterNpc.multiPlayerOnly}
            onChange={() => handleChange('multiPlayerOnly')}
          />
          &nbsp; Multiple Players{' '}
        </div>
      </div>

      <Table
        columns={columns}
        data={data?.adminListGames?.data || []}
        loading={loading}
        page={page}
        limit={pageLimit}
        updatePageLimit={updatePageLimit}
        total={data?.adminListGames?.totalCount || 1}
        showPagination
        onPrevPage={onPrevPage}
        onNextPage={onNextPage}
        scroll={{ y : 450}}
      />
    </div>
  );
}
