import axios from "axios";
import { getStorageData, StorageKeys } from "./storageManager";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_REST_API_URL,
  timeout: 30 * 1000,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = getStorageData(StorageKeys.AuthToken);
    if (token) {
      config.headers["authorization-token"] = token;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export async function createSong(songData) {
  return axiosInstance.post("/rest/upload-window", songData);
}

export async function updatePlaylistBanner(songData) {
  return axiosInstance.post("/rest/upload-window", songData);
}

export async function createBannerImage(bannerData) {
  console.log("Rest service: request gets here")
  console.log("banner data", bannerData);
  return axiosInstance.post("/rest/upload-window", bannerData);
}