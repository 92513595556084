import { gql } from '@apollo/client';

export const GET_ADMINS = gql`
  query adminListStaff(
    $limit: Int!
    $offset: Int!
    $nameLike: String
    $sortType: MuzingoArraySortOption
    $sortOrder: MuzingoArraySortOption
  ) {
    adminListStaff(limit: $limit, offset: $offset, nameLike: $nameLike, sortType: $sortType, sortOrder: $sortOrder) {
      totalCount
      data {
        id
        fullname
        email
        dateRegistered
        accountActive
      }
    }
  }
`;

export const GET_USERS = gql`
  query adminListUsers(
    $limit: Int!
    $offset: Int!
    $nameLike: String
    $sortType: MuzingoArraySortOption
    $sortOrder: MuzingoArraySortOption
  ) {
    adminListUsers(limit: $limit, offset: $offset, nameLike: $nameLike, sortType: $sortType, sortOrder: $sortOrder) {
      totalCount
      data {
        id
        fullname
        accountActive
        totalGamesPlayed
        email
        dateRegistered
        username
        userExperience
        activePlan
        userBundleType
        lastPlayedGameTime
        location
        totalGamesWon
        lastWonGameTime
        totalGamesHosted
        lastHostedGameTime
        winningStreak {
          count
        }
        dailyGameStreak {
          count
        }
        gameHostedStreak {
          count
        }
      }
    }
  }
`;

export const GET_GAMES = gql`
  query adminListGames(
    $limit: Int!
    $offset: Int!
    $nameLike: String
    $filterNpc: Boolean
    $filterCompleted: Boolean
    $filterPlayers: Boolean
    $sortType: MuzingoArraySortOption
    $sortOrder: MuzingoArraySortOption
  ) {
    adminListGames(
      limit: $limit
      offset: $offset
      nameLike: $nameLike
      filterNpc: $filterNpc
      filterCompleted: $filterCompleted
      filterPlayers: $filterPlayers
      sortType: $sortType
      sortOrder: $sortOrder
    ) {
      totalCount
      data {
        id
        hostId
        gameStarted
        gameStartedDateTime
        gameCompleted
        gameCompletedDateTime
        gameCode
        winningPattern
        gameMode
        gameType
        gameTitle
        winnerFullName
        host {
          id
          fullname
        }
        streamMode
        players {
          id
          username
          fullname
        }
        playlist {
          id
          title
        }
        prize {
          id
          gamePrize
        }
        winnerId
        createdAt
      }
    }
  }
`;

export const GET_LEADERBOARD = gql`
  query adminGetLeaderBoard(
    $limit: Int!
    $offset: Int!
    $period: String
    $nameLike: String
    $sortColumn: String
    $sortType: MuzingoArraySortOption
    $sortOrder: MuzingoArraySortOption
    $accuracyFilter: [Float]
  ) {
    adminGetLeaderBoard(
      limit: $limit
      offset: $offset
      period: $period
      nameLike: $nameLike
      sortColumn: $sortColumn
      sortType: $sortType
      sortOrder: $sortOrder
      accuracyFilter: $accuracyFilter
    ) {
      totalCount
      data {
        id
        hostName
        playerName
        gameCode
        gameCompletedDateTime
        accuracy
        numberOfRounds
        bestRoundTime
        bestWarmUpTime
        averageTimePerRound
        totalTimeForAllRounds
        bestAverageTimePerRound
        playlistName
      }
    }
  }
`;

export const GET_PLAYLIST = gql`
  query listPlaylist(
    $limit: Int!
    $offset: Int!
    $filterBy: TimePlayedEnum
    $nameLike: String
    $sortType: MuzingoArraySortOption
    $sortOrder: MuzingoArraySortOption
    $addInactive: Boolean
  ) {
    listPlaylist(
      limit: $limit
      offset: $offset
      filterBy: $filterBy
      nameLike: $nameLike
      sortType: $sortType
      sortOrder: $sortOrder
      addInactive: $addInactive
    ) {
      totalCount
      data {
        id
        title
        seoTitle
        seoDescription
        totalTracks
        totalPlays
        tags
        imageUrl
        createdAt
        status
      }
    }
  }
`;

export const GET_SONGS = gql`
  query listSongs(
    $limit: Int!
    $offset: Int!
    $filterBy: TimePlayedEnum
    $nameLike: String
    $sortType: MuzingoArraySortOption
    $sortOrder: MuzingoArraySortOption
  ) {
    listSongs(
      limit: $limit
      offset: $offset
      filterBy: $filterBy
      nameLike: $nameLike
      sortType: $sortType
      sortOrder: $sortOrder
    ) {
      totalCount
      data {
        id
        album
        title
        isrCode
        featuredPlaylists
        totalPlays
        artist
        createdAt
        trackUrl
        featuredOnRadio
        trackLength
      }
    }
  }
`;

export const GET_SINGLE_GAME_WITH_USER = gql`
  query adminSearchUserByGame($gameId: String!, $limit: Int!, $offset: Int!) {
    adminSearchUserByGame(gameId: $gameId, limit: $limit, offset: $offset) {
      totalCount
      status
      message
      game {
        id
        gameCode
        gameMode
        winningPattern
        winnerFullName
        playlistId
        hostId
        gameType
        gameTitle
        gameCompleted
        gameCompletedDateTime
        gameStartedDateTime
        gameStarted
        streamMode
        players {
          id
          fullname
          email
          location
          username
          activePlan
          totalGamesPlayed
        }
      }
      prize {
        id
        gamePrize
      }
      host {
        id
        fullname
        username
        location
        email
        activePlan
      }
      playlist {
        id
        title
        imageUrl
        seoTitle
        totalPlays
        totalTracks
        clickCount
      }
      winner {
        id
        fullname
        username
        location
        email
        activePlan
      }
    }
  }
`;

export const GET_SINGLE_PLAYLIST_WITH_SONGS = gql`
  query adminSearchSongsByPlaylist($playlistId: ID!, $limit: Int!, $offset: Int!, $filterBy: TimePlayedEnum) {
    adminSearchSongsByPlaylist(playlistId: $playlistId, limit: $limit, offset: $offset, filterBy: $filterBy) {
      totalCount
      playlist {
        id
        title
        seoTitle
        totalTracks
        totalPlays
        imageUrl
        createdAt
      }
      tracks {
        id
        album
        title
        featuredPlaylists
        totalPlays
        artist
        createdAt
        featuredOnRadio
        trackUrl
        trackLength
      }
    }
  }
`;

export const GET_PLATFORM_ANALYTICS = gql`
  query adminGetPlatformAnalytics($period: AnalyticsPeriod, $endDate: String, $startDate: String) {
    adminGetPlatformAnalytics(period: $period, endDate: $endDate, startDate: $startDate) {
      status
      message
      data {
        totalUsers
        totalGamesPlayed
        totalHosts
        totalPlayer
      }
    }
  }
`;
export const GET_CORE_METRICS = gql`
  query adminGetCoreMetricAnalysis($period: AnalyticsPeriod, $endDate: String, $startDate: String) {
    adminGetCoreMetricAnalysis(period: $period, endDate: $endDate, startDate: $startDate) {
      status
      message
      data {
        creatorHost
        primeHosts
        activeHosts
        newbies
        teamBrand
        activeNewbies
        # hostsNo
      }
    }
  }
`;

export const GET_TOP_USERS = gql`
  query adminGetTopPlayers(
    $limit: Int!
    $offset: Int!
    $period: AnalyticsPeriod
    $allUsers: Boolean!
    $endDate: String
    $startDate: String
  ) {
    adminGetTopPlayers(
      limit: $limit
      offset: $offset
      period: $period
      allUsers: $allUsers
      endDate: $endDate
      startDate: $startDate
    ) {
      totalCount
      data {
        id
        fullname
        accountActive
        totalGamesPlayed
        totalUniquePlayers
        hostStreak
        email
        dateRegistered
        username
        userExperience
        lastPlayedGameTime
        location
        totalGamesWon
        lastWonGameTime
        winningStreak {
          count
        }
        dailyGameStreak {
          count
        }
        gameHostedStreak {
          count
        }
        totalGamesHosted
        lastHostedGameTime
      }
    }
  }
`;

export const GET_TRENDING_PLAYLIST = gql`
  query getTrendingPlaylist($limit: Int!) {
    getTrendingPlaylist(limit: $limit) {
      totalCount
      data {
        id
        title
        totalTracks
        totalPlays
        imageUrl
        createdAt
      }
    }
  }
`;

// TODO:
// query to getting all transaction details

export const GET_TRANSACTIONS = gql`
  query adminListAllTransactions($limit: Int!, $offset: Int!, $filterBy: TimePlayedEnum) {
    adminListAllTransactions(limit: $limit, offset: $offset, filterBy: $filterBy) {
      status
      message
      data {
        index
        stripeId
        bundleId
        bunldeTitle
        amount
        date
        userFullName
        userEmail
        userId
        receiptUrl
      }
    }
  }
`;

export const GET_BUNDLES = gql`
  query adminListBundle(
    $limit: Int!
    $offset: Int!
    $nameLike: String
    $sortType: MuzingoArraySortOption
    $sortOrder: MuzingoArraySortOption
  ) {
    adminListBundle(limit: $limit, offset: $offset, nameLike: $nameLike, sortType: $sortType, sortOrder: $sortOrder) {
      totalCount
      data {
        id
        title
        costOfAcquisition
        defaultMaxPlaylist
        maxGameParticipant
        discount
        subscriptionType
      }
    }
  }
`;

export const GET_SONG_INFO = gql`
  query getSongById($id: ID!) {
    getSongById(id: $id) {
      status
      message
      data {
        id
        title
        featuredPlaylists
        artist
        album
        totalPlays
        trackUrl
        featuredOnRadio
        createdAt
      }
    }
  }
`;

export const GET_CURRENT_RADIO_PLAY = gql`
  query getCurrentRadioStationId($gameCode: String!, $playlistId: String) {
    getCurrentRadioStationId(gameCode: $gameCode, playlistId: $playlistId) {
      status
      message
      playlistId
      radioUrl
    }
  }
`;

export const GET_TRIAL_CAMPAIGN = gql`
  query adminListTrialCampaign($limit: Int!, $offset: Int!) {
    adminListTrialCampaign(limit: $limit, offset: $offset) {
      totalCount
      data {
        id
        title
        startDate
        endDate
        campaingClass
        noOfTrialDays
        noOfTrialGames
        campaignType
        selectablePlaylistIds
        active
      }
    }
  }
`;

export const GET_FREE_TRIAL_PLAYLIST = gql`
  query listPlaylist($limit: Int!, $offset: Int!) {
    listPlaylist(limit: $limit, offset: $offset) {
      totalCount
      data {
        id
        title
      }
    }
  }
`;

export const GET_ACTIVE_BANNERS = gql`
  query {
  getActiveBanners {
    status
    message
    data {
      id
      title
      description
      image
      link
      status
    }
  }
}
`;

export const GET_ALL_BANNERS = gql`
  query {
  getAllBanners {
    status
    message
    data {
      id
      title
      description
      image
      link
      status
    }
  }
}
`;

export const GET_FIREBASE_CREDENTIALS = `
  {
    getFirebaseCredentials {
      status
      message
      data {
        credentials {
          apiKey
          authDomain
          projectId
          storageBucket
          messagingSenderId
          appId
          measurementId
        }
        key
      }
    }
  }
`;

export const SET_FIREBASE_TOKEN = gql`
  mutation setToken($token: String!) {
    setFirebaseToken(token: $token) {
      status
      message
    }
  }
`;
