export const partyInviteOpts = [
  {
    value: '',
    label: 'Select invite type',
  },
  {
    value: 'VIRTUAL',
    label: 'virtual',
  },
  {
    value: 'OFFLINE',
    label: 'offline',
  },
];

export const notificationTriggers = [
  {
    title: 'New feature',
    type: 'notifyOfNewFeature',
  },
  {
    title: 'Party invitation',
    type: 'partyInvitation',
  },
  {
    title: 'Game tips',
    type: 'sendGameTip',
  },
  {
    title: 'Create Banner',
    type: 'createBanner',
  },
  {
    title: 'Update Banner',
    type: 'updateBannerStatus',
  },
];

export const bannerStatusOpts = [
  {
    value: '',
    label: 'Select banner status',
  },
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'INACTIVE',
    label: 'Inactive',
  },
];

export const updateBannerStatusOpts = [
  {
    value: 'ACTIVE',
    label: 'Active',
  },
  {
    value: 'INACTIVE',
    label: 'Inactive',
  },
]

export const bannerTypeOpts = [
  {
    value: '',
    label: 'Select banner type',
  },
  {
    value: 'MODAL',
    label: 'Modal',
  },
  {
    value: 'NOTIFICATION',
    label: 'Notification',
  },
]
