import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { GET_FIREBASE_CREDENTIALS } from '../lib/graphQl/query';

const makeRequest = async (graphJson, variables = null) => {
  const response = await fetch(process.env.REACT_APP_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ query: graphJson, variables }),
  });
  const { data } = await response.json();

  return data;
};

async function getFirebaseCredentials() {
  try {
    const data = await makeRequest(GET_FIREBASE_CREDENTIALS);

    if (!data?.getFirebaseCredentials?.data) {
      throw new Error(data?.getFirebaseCredentials?.message);
    }

    return data.getFirebaseCredentials.data;
  } catch (error) {
    return null;
  }
}

let app, messaging;

async function initialize() {
  try {
    const firebaseConfig = await getFirebaseCredentials();

    if (firebaseConfig !== null && firebaseConfig?.credentials) {
      app = initializeApp(firebaseConfig.credentials);
      messaging = getMessaging(app); // Pass the initialized app to getMessaging
    } else {
      throw new Error('Firebase credentials not found');
    }

    if (!firebaseConfig?.key) {
      throw new Error('No firebase public key was added');
    }

    const newToken = await getToken(messaging, {
      vapidKey: firebaseConfig.key,
    });

    if (!newToken) {
      throw new Error('No token was generated');
    }

    return newToken;
  } catch (error) {
    console.error('Error initializing Firebase:', error.message);
    return '';
  }
}

export { app, messaging, initialize };
